import styles from './ResponsesMenu.module.scss';
import { Response, Checklist as ChecklistType } from '../../sharedTypes';
import { ReactComponent as PlayIcon } from '../icons/play_circle_filled.svg';
import { formatTimeDifference } from '../../utils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useNavigation } from '../../NavigationContext';
import { useGetAnswersQuery, useGetResponseQuery } from '../../store/api';
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Avatar from 'components/Avatar/Avatar';
import CoverImage from 'components/shared/CoverImage/CoverImage';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import InfiniteScroll, { PaginatedResponse } from './InfiniteScroll';
import { applyRulesToResponse } from '../../utils/applyRules';

const ITEMS_PER_PAGE = 10;

const ResponseItem = ({
	answerInfos: response,
	index,
	style,
	openedResponse,
	setOpenedResponse,
	setNavigation,
	checklistId,
	setItemSize,
}: {
	answerInfos: Response & {
		firstAnswer: string;
		answeredRequiredQuestions: number;
		totalRequiredQuestions: number;
	};
	index: number;
	style: React.CSSProperties;
	openedResponse: Response | null;
	setOpenedResponse: (response: Response | null) => void;
	setNavigation: (navigation: string) => void;
	checklistId: string;
	setItemSize: (index: number, size: number) => void;
}) => {
	const [retreiveResponse, setRetreiveResponse] = useState<string | null>(null);
	const itemRef = useRef<HTMLDivElement>(null);

	const { data: responseData } = useGetResponseQuery(response.responseId, {
		skip: !retreiveResponse,
	});

	useEffect(() => {
		if (itemRef.current) {
			setItemSize(index, itemRef.current.getBoundingClientRect().height);
		}
	}, [setItemSize, index, response]);

	useEffect(() => {
		if (retreiveResponse && responseData) {
			setOpenedResponse(responseData as Response);
		}
	}, [retreiveResponse, responseData, setOpenedResponse]);

	const progressPercentage = useMemo(() => {
		return (
			(response.answeredRequiredQuestions / response.totalRequiredQuestions) *
			100
		);
	}, [response]);

	return (
		<div style={style}>
			<div ref={itemRef} className={styles.menuItem}>
				<div
					role="button"
					onClick={() => {
						setNavigation(`checklist_${checklistId}`);
						setRetreiveResponse(response.responseId);
					}}
					className={
						openedResponse?.responseId === response.responseId
							? styles.selected
							: ''
					}
				>
					<div className={styles.modifiedBy}>
						{response.modifiedBy?.slice(0, 2).map((user, userIndex) => (
							<Avatar
								key={`${response.responseId}_${user.userId || userIndex}`}
								name={`${user.userFirstName} ${user.userLastName}`}
								id={user.userId}
								small
							/>
						))}
						{response.modifiedBy && response.modifiedBy.length > 2 && (
							<Avatar
								key={`${response.responseId}_more`}
								name={`+ ${response.modifiedBy.length - 2}`}
								color="grey"
								small
								column
							/>
						)}
					</div>
					<div className={styles.column}>
						<div className={styles.tabBarItems}>
							{format(new Date(response.timestamp), 'd MMM yyyy, HH:mm', {
								locale: fr,
							})}
						</div>
						<div className={styles.title}>
							{`${(() => {
								if (response.firstAnswer) {
									return response.firstAnswer;
								}

								return `Réponse #${response.index}`;
							})()}`}
						</div>
						<div className={styles.remaining}>
							<div className={styles.progressContainer}>
								{progressPercentage === 100 ? (
									<div className={styles.tabBarItems}>{'Terminé'}</div>
								) : (
									<>
										<div className={styles.progressBarBackground}>
											<div
												className={styles.progressBar}
												style={{
													width: `${progressPercentage}%`,
												}}
											/>
										</div>
										<div className={styles.tabBarItems}>
											{response.answeredRequiredQuestions} sur{' '}
											{response.totalRequiredQuestions} questions
										</div>
									</>
								)}
							</div>
						</div>
					</div>
					{openedResponse?.responseId === response.responseId && (
						<div className={styles.playIcon}>
							<PlayIcon />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default function ResponsesMenu({
	checklistId,
	setNavigation,
	checklist,
}: {
	checklistId: string;
	setNavigation: (navigation: string) => void;
	checklist?: ChecklistType;
}) {
	const [page, setPage] = useState(1);
	const {
		data: answersData,
		isFetching,
		isSuccess,
	} = useGetAnswersQuery(
		{
			ids: checklistId ? [checklistId] : undefined,
			page,
			limit: ITEMS_PER_PAGE,
		},
		{
			skip: !checklistId,
		}
	);

	const isIntegrated = useSelector(
		(state: RootState) => state.user.isIntegrated
	);
	const [itemClicked, setItemClicked] = useState<number>(0);
	const { openedResponse, setOpenedResponse, setResponseMode } =
		useNavigation();
	const [lastModifiedTime, setLastModifiedTime] = useState(
		formatTimeDifference(
			new Date(),
			new Date(checklist?.lastModified?.timestamp || new Date())
		)
	);

	const queryParams = useMemo(
		() => ({
			checklistId,
			page_size: ITEMS_PER_PAGE,
		}),
		[checklistId]
	);

	const ResponseRow = useCallback(
		({ index, style, data, setItemSize }: any) => {
			const response = data[index];
			if (!response) return null;

			if (!response.state) {
				return (
					<ResponseItem
						answerInfos={response}
						index={index}
						style={style}
						openedResponse={openedResponse}
						setOpenedResponse={setOpenedResponse}
						setNavigation={setNavigation}
						checklistId={checklistId}
						setItemSize={setItemSize}
					/>
				);
			}

			const responseState =
				response.state instanceof Map
					? { chapters: response.state.get('chapters') }
					: { chapters: response.state.chapters };

			const responseWithRules = applyRulesToResponse(
				{ ...response, state: responseState },
				response.answers
			);

			const chaptersWithCounts =
				responseWithRules.state?.chapters?.map((chapter) => {
					const totalRequiredQuestions = (chapter.questions || []).filter(
						(q) => q.required
					).length;
					const answeredRequiredQuestions = (chapter.questions || []).filter(
						(q) => q.required && response.answers?.[q.questionId]
					).length;
					return {
						...chapter,
						totalRequiredQuestions,
						answeredRequiredQuestions,
					};
				}) || [];

			const responseWithCounts = {
				...responseWithRules,
				state: {
					...responseWithRules.state,
					chapters: chaptersWithCounts,
				},
			};

			return (
				<ResponseItem
					answerInfos={responseWithCounts as any}
					index={index}
					style={style}
					openedResponse={openedResponse}
					setOpenedResponse={setOpenedResponse}
					setNavigation={setNavigation}
					checklistId={checklistId}
					setItemSize={setItemSize}
				/>
			);
		},
		[openedResponse, setOpenedResponse, setNavigation, checklistId]
	);

	const wrappedQuery = useCallback(
		() =>
			({
				data: answersData
					? {
							count: answersData.pagination.total,
							next:
								answersData.pagination.page < answersData.pagination.pages
									? 'next'
									: null,
							previous: answersData.pagination.page > 1 ? 'prev' : null,
							results: answersData.data,
					  }
					: undefined,
				isFetching,
				isSuccess,
			} as {
				data?: PaginatedResponse<unknown>;
				isFetching: boolean;
				isSuccess: boolean;
			}),
		[answersData, isFetching, isSuccess]
	);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setLastModifiedTime(
				formatTimeDifference(
					new Date(),
					new Date(checklist?.lastModified?.timestamp || new Date())
				)
			);
		}, 10000);

		return () => clearInterval(intervalId);
	}, [checklist?.lastModified?.timestamp]);

	return (
		<div className={styles.menu}>
			{!isIntegrated && (
				<div className={styles.navButtons}>
					<button className={styles.selected}>Réponses</button>
					<button
						onClick={() => {
							setOpenedResponse(null);
							setResponseMode(false);
							setNavigation(`checklist_${checklistId}`);
						}}
					>
						Éditer la Checklist
					</button>
				</div>
			)}
			<div
				onClick={() => setNavigation('checklist')}
				className={styles.navigationHeader}
			>
				<CoverImage id={checklistId} coverImage={checklist?.coverImage} />
				<div className={styles.column}>
					<h5>{checklist?.title}</h5>
					<div className={styles.lastEdit}>
						<Avatar
							small
							name={`${checklist?.lastModified?.userFirstName} ${checklist?.lastModified?.userLastName}`}
						/>
						{lastModifiedTime === '0 seconde'
							? "modifié à l'instant"
							: `modifié il y a ${lastModifiedTime}`}
					</div>
				</div>
			</div>
			<div className={styles.responsesList}>
				<InfiniteScroll
					useQuery={wrappedQuery}
					row={ResponseRow}
					itemSize={100}
					queryParams={queryParams}
					itemClicked={itemClicked}
					undoItemClicked={() => setItemClicked(0)}
					onLoadMore={() => setPage((p) => p + 1)}
				/>
			</div>
		</div>
	);
}

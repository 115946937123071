import { Response, Chapter, Question, Rule } from '../sharedTypes';

export const applyRulesToResponse = (response: Response, answers: any) => {
	const toMaskQuestions = new Set<string>();
	const dependencies: { [key: string]: string[] } = {};

	// Build dependency map
	response.state?.chapters?.forEach((chapter: Chapter) => {
		chapter.questions?.forEach((question: Question) => {
			if (question.rules) {
				question.rules.forEach((rule: Rule) => {
					rule.targetQuestionIds?.forEach((targetQuestionId) => {
						if (!dependencies[question.questionId]) {
							dependencies[question.questionId] = [];
						}
						dependencies[question.questionId].push(targetQuestionId);
					});
				});
			}
		});
	});

	// Function to recursively hide dependent questions
	const hideQuestion = (questionId: string) => {
		if (!toMaskQuestions.has(questionId)) {
			toMaskQuestions.add(questionId);
			if (dependencies[questionId]) {
				dependencies[questionId].forEach((dependentQuestionId) => {
					hideQuestion(dependentQuestionId);
				});
			}
		}
	};

	// Apply rules to determine which questions to hide
	response.state?.chapters?.forEach((chapter: Chapter) => {
		chapter.questions?.forEach((question: Question) => {
			if (answers && answers[question.questionId]) {
				if (question.rules) {
					question.rules.forEach((rule: Rule) => {
						let shouldHide = false;
						const answer = answers[question.questionId] as any;
						const valueIncludesAnswer = (rule.value as string[]).includes(
							answer
						);

						if (rule.condition) {
							// If answer matches the condition
							if (valueIncludesAnswer) {
								if (!rule.action) {
									shouldHide = true;
								}
							} else {
								// If answer doesn't match the condition
								if (rule.action) {
									shouldHide = true;
								}
							}
						} else {
							// If there's no condition
							if (valueIncludesAnswer) {
								if (rule.action) {
									shouldHide = true;
								}
							} else {
								if (!rule.action) {
									shouldHide = true;
								}
							}
						}

						if (shouldHide) {
							rule.targetQuestionIds?.forEach((targetQuestionId) => {
								hideQuestion(targetQuestionId);
							});
						}
					});
				}
			} else {
				// If question has no answer and depends on a hidden question, hide it
				if (toMaskQuestions.has(question.questionId)) {
					hideQuestion(question.questionId);
				}
			}
		});
	});

	// Remove hidden questions from chapters
	const filteredChapters = response.state?.chapters
		?.map((chapter: Chapter) => {
			const newQuestions = chapter.questions?.filter(
				(question: Question) => !toMaskQuestions.has(question.questionId)
			);
			return { ...chapter, questions: newQuestions };
		})
		.filter((chapter) => chapter.questions?.length);

	return {
		...response,
		state: { ...response.state, chapters: filteredChapters },
	};
};

import { useMemo, useState, useEffect } from 'react';
import { useGetAnswerQuery, useGetResponseQuery } from '../../store/api';
import PdfReport from './PdfReport';
import styles from './PdfReport.module.scss';
import { applyRulesToResponse } from 'utils/applyRules';

export default function PdfPreview({ responseId }: { responseId: string }) {
	const { data: response } = useGetResponseQuery(responseId);
	const { data: answers } = useGetAnswerQuery(responseId);
	const [summaryPages, setSummaryPages] = useState<any[][]>([]);

	useEffect(() => {
		document.documentElement.classList.add('pdf-preview-mode');
		return () => {
			document.documentElement.classList.remove('pdf-preview-mode');
		};
	}, []);

	const responseWithRulesApplied = useMemo(() => {
		if (!response) return response;
		return applyRulesToResponse(response, answers);
	}, [response, answers]);

	const missionDetails = localStorage.getItem('iframeMission');

	if (!response) return null;

	return (
		<div className={styles.pdfPreview}>
			<PdfReport
				response={responseWithRulesApplied as any}
				answers={answers as any}
				checklist={responseWithRulesApplied?.state as any}
				currentAnswers={answers as any}
				setSummaryPages={setSummaryPages}
				initialSummaryPages={summaryPages}
				missionDetails={missionDetails || ''}
			/>
		</div>
	);
}

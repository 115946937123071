import { Middleware, Action } from '@reduxjs/toolkit';
import { api } from './api';
import { getSocket, initializeSocket, isSocketConnected } from './socket';
import {
	Question,
	Chapter,
	Checklist,
	Answer as Answers,
	// AnswersResponse,
	Comment,
	Reply as ReplyType,
	Folder,
	Event,
	HistoryEvent,
} from '../sharedTypes';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from './rootReducer';

type User = {
	checklistId: string;
	questionId: string;
	userId: string;
	userFirstName: string;
	userLastName: string;
};

const addFolder = (
	newFolder: Folder
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData('getFolders', {}, (draft: Folder[]) => {
			draft.push(newFolder);
		})(dispatch, getState, undefined);
	};
};

const updateFolder = (
	updatedFolder: Folder
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData('getFolders', {}, (draft: Folder[]) => {
			const index = draft.findIndex(
				(folder) => folder.folderId === updatedFolder.folderId
			);
			if (index !== -1) {
				draft[index] = updatedFolder;
			}
		})(dispatch, getState, undefined);
	};
};

const removeFolder = (
	folderId: string
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData('getFolders', {}, (draft: Folder[]) => {
			const index = draft.findIndex((folder) => folder.folderId === folderId);
			if (index !== -1) {
				draft.splice(index, 1);
			}
		})(dispatch, getState, undefined);
	};
};

const addChecklist = (
	newChecklist: Checklist
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklists',
			undefined,
			(draft: { state: Checklist; checklistId: string; title: string }[]) => {
				draft.push({
					state: newChecklist,
					checklistId: newChecklist.checklistId,
					title: newChecklist.title,
				});
			}
		)(dispatch, getState, undefined);
		api.util.updateQueryData(
			'getFolder',
			{ id: newChecklist.folderId ?? '' },
			(draft: Checklist[]) => {
				draft.push(newChecklist);
			}
		)(dispatch, getState, undefined);
	};
};

const deleteChecklist = (
	deletedChecklist: Checklist
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getFolder',
			{ id: deletedChecklist.folderId ?? '' },
			(draft: Checklist[]) => {
				const index = draft.findIndex(
					(checklist) => checklist.checklistId === deletedChecklist.checklistId
				);
				if (index !== -1) {
					draft.splice(index, 1);
				}
			}
		)(dispatch, getState, undefined);
	};
};

const moveChecklistToFolder = (
	movedChecklist: Checklist,
	oldFolderId: string
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getFolder',
			{ id: oldFolderId },
			(draft: Checklist[]) => {
				const index = draft.findIndex(
					(checklist) => checklist.checklistId === movedChecklist.checklistId
				);
				if (index !== -1) {
					draft.splice(index, 1);
				}
			}
		)(dispatch, getState, undefined);

		api.util.updateQueryData(
			'getFolder',
			{ id: movedChecklist.folderId ?? '' },
			(draft: Checklist[]) => {
				draft.push(movedChecklist);
			}
		)(dispatch, getState, undefined);
	};
};

const updateChecklist = (
	updatedChecklist: Checklist
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklists',
			undefined,
			(
				draft: {
					state: Checklist;
					checklistId: string;
					title: string;
					coverImage: string;
				}[]
			) => {
				const index = draft.findIndex(
					(checklist) => checklist.checklistId === updatedChecklist.checklistId
				);
				if (index !== -1) {
					if (updatedChecklist.title) {
						draft[index].title = updatedChecklist.title;
					}
					if (updatedChecklist.coverImage) {
						draft[index].coverImage = updatedChecklist.coverImage;
					}
				}
			}
		)(dispatch, getState, undefined);

		api.util.updateQueryData(
			'getChecklist',
			updatedChecklist.checklistId,
			(draft: {
				state: { title: string; coverImage: string; chapters: Chapter[] };
			}) => {
				if (updatedChecklist.title) {
					draft.state.title = updatedChecklist.title;
				}
				if (updatedChecklist.coverImage) {
					draft.state.coverImage = updatedChecklist.coverImage;
				}
			}
		)(dispatch, getState, undefined);
	};
};

const updateChecklistLastModified = (
	updatedChecklist: Checklist & { checklistId: string }
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklist',
			updatedChecklist.checklistId,
			(draft: {
				state: Checklist;
				lastModified: {
					timestamp: string;
					userFirstName: string;
					userLastName: string;
				};
			}) => {
				draft.lastModified = {
					timestamp: new Date(
						updatedChecklist.lastModified.timestamp
					).toISOString(),
					userFirstName: updatedChecklist.lastModified.userFirstName,
					userLastName: updatedChecklist.lastModified.userLastName,
				};
			}
		)(dispatch, getState, undefined);
	};
};

const addChapter = (
	newChapter: Chapter
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklist',
			newChapter.checklistId,
			(draft: { state: { title: string; chapters: Chapter[] } }) => {
				draft.state.chapters.push(newChapter);
			}
		)(dispatch, getState, undefined);
	};
};

const deleteChapter = (
	chapterId: string,
	checklistId: string
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklist',
			checklistId,
			(draft: { state: { title: string; chapters: Chapter[] } }) => {
				const index = draft.state.chapters.findIndex(
					(chapter) => chapter.chapterId === chapterId
				);
				if (index !== -1) {
					draft.state.chapters.splice(index, 1);
				}
			}
		)(dispatch, getState, undefined);
	};
};

const updateChapter = (
	updatedChapter: Chapter
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklist',
			updatedChapter.checklistId,
			(draft: { state: { title: string; chapters: Chapter[] } }) => {
				const index = draft.state.chapters.findIndex(
					(chapter) => chapter.chapterId === updatedChapter.chapterId
				);
				if (index !== -1) {
					draft.state.chapters[index] = updatedChapter;
				}
			}
		)(dispatch, getState, undefined);
	};
};

const reorderChapters = (data: {
	checklistId: string;
	chapterOrders: string[];
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklist',
			data.checklistId,
			(draft: { state: { title: string; chapters: Chapter[] } }) => {
				data.chapterOrders.forEach((order, index) => {
					const chapter = draft.state.chapters.find(
						(ch) => ch.chapterId === order
					);
					if (chapter) {
						chapter.index = index;
					}
				});
			}
		)(dispatch, getState, undefined);
	};
};

const addQuestion = (
	newQuestion: Question & {
		rulesToRestore?: {
			chapterId: string;
			questionId: string;
			ruleIndex: number;
		}[];
	}
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklist',
			newQuestion.checklistId,
			(draft: { state: { title: string; chapters: Chapter[] } }) => {
				const chapter = draft.state.chapters.find(
					(ch) => ch.chapterId === newQuestion.chapterId
				);
				if (chapter) {
					if (!chapter.questions) chapter.questions = [];
					chapter.questions.push(newQuestion);
				}

				if (newQuestion.rulesToRestore) {
					newQuestion.rulesToRestore.forEach(
						({ chapterId, questionId, ruleIndex }) => {
							const targetChapter = draft.state.chapters.find(
								(ch) => ch.chapterId === chapterId
							);
							if (targetChapter) {
								const targetQuestion = (targetChapter.questions || []).find(
									(q) => q.questionId === questionId
								);
								if (targetQuestion) {
									if (!targetQuestion.rules) targetQuestion.rules = [];
									targetQuestion.rules[ruleIndex].targetQuestionIds.push(
										newQuestion.questionId
									);
								}
							}
						}
					);
				}
			}
		)(dispatch, getState, undefined);

		api.util.updateQueryData(
			'getEditingChecklist',
			newQuestion.checklistId,
			(draft: User[]) => {
				const index = draft.findIndex(
					(user) => user.userId === newQuestion.userId
				);
				if (index !== -1) {
					draft[index].questionId = newQuestion.questionId;
				}
			}
		)(dispatch, getState, undefined);
	};
};

const deleteQuestion = (
	questionId: string,
	chapterId: string,
	checklistId: string
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklist',
			checklistId,
			(draft: { state: { title: string; chapters: Chapter[] } }) => {
				draft.state.chapters.forEach((chapter) => {
					if (chapter.chapterId === chapterId) {
						const index = (chapter.questions || []).findIndex(
							(q) => q.questionId === questionId
						);
						if (index !== -1) {
							(chapter.questions || []).splice(index, 1);
						}
					}

					chapter.questions?.forEach((question) => {
						if (question.rules) {
							question.rules?.forEach((rule) => {
								const targetIndex = rule.targetQuestionIds.indexOf(questionId);
								if (targetIndex !== -1) {
									rule.targetQuestionIds.splice(targetIndex, 1);
								}
							});
						}
					});
				});
			}
		)(dispatch, getState, undefined);
	};
};

const updateQuestion = (
	updatedQuestion: Question
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklist',
			updatedQuestion.checklistId,
			(draft: { state: { title: string; chapters: Chapter[] } }) => {
				const chapter = draft.state.chapters.find(
					(ch) => ch.chapterId === updatedQuestion.chapterId
				);
				if (chapter) {
					const index = (chapter.questions || []).findIndex(
						(q) => q.questionId === updatedQuestion.questionId
					);
					if (index !== -1) {
						(chapter.questions || []).splice(index, 1, updatedQuestion);
					}
				}
			}
		)(dispatch, getState, undefined);

		api.util.updateQueryData(
			'getEditingChecklist',
			updatedQuestion.checklistId,
			(draft: User[]) => {
				const index = draft.findIndex(
					(user) => user.userId === updatedQuestion.userId
				);
				if (index !== -1) {
					draft[index].questionId = updatedQuestion.questionId;
				}
			}
		)(dispatch, getState, undefined);
	};
};

const reorderQuestions = (data: {
	checklistId: string;
	chapterId: string;
	targetChapterId: string;
	questionOrders: string[];
	targetQuestionOrders: string[];
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getChecklist',
			data.checklistId,
			(draft: { state: { title: string; chapters: Chapter[] } }) => {
				const chapter = draft.state.chapters.find(
					(ch) => ch.chapterId === data.chapterId
				);
				const targetChapter = draft.state.chapters.find(
					(ch) => ch.chapterId === data.targetChapterId
				);
				const allQuestions = draft.state.chapters.flatMap((ch) => ch.questions);
				if (chapter) {
					const questions: Question[] = [];
					data.questionOrders?.forEach((order, index) => {
						const question = {
							...allQuestions.find((q) => q?.questionId === order),
						} as Question;
						if (question) {
							question.index = index;
							question.chapterId = chapter.chapterId;
							questions.push(question);
						}
					});
					chapter.questions = questions;
				}
				if (targetChapter) {
					const questions: Question[] = [];
					data.targetQuestionOrders.forEach((order, index) => {
						const question = {
							...allQuestions.find((q) => q?.questionId === order),
						} as Question;
						if (question) {
							question.index = index;
							question.chapterId = targetChapter.chapterId;
							questions.push(question);
						}
					});
					targetChapter.questions = questions;
				}
			}
		)(dispatch, getState, undefined);
	};
};

// const addResponse = (
// 	newResponse: Response,
// 	checklistId: string
// ): ThunkAction<void, RootState, unknown, Action<string>> => {
// 	return (dispatch, getState) => {
// 		api.util.updateQueryData('getResponses', checklistId, (draft: any) => {
// 			draft.push(newResponse);
// 		})(dispatch, getState, undefined);
// 	};
// };

const updateResponse = (data: {
	responseId: string;
	validatedBy?: {
		userId: string;
		userFirstName: string;
		userLastName: string;
		timestamp: Date;
	};
	finishedBy?: {
		userId: string;
		userFirstName: string;
		userLastName: string;
		timestamp: Date;
	};
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getResponses',
			{ id: data.responseId },
			(draft: any) => {
				if (data.validatedBy) {
					draft.data.forEach((response: any) => {
						if (response.responseId === data.responseId) {
							response.validatedBy = data.validatedBy;
						}
					});
				}
				if (data.finishedBy) {
					draft.data.forEach((response: any) => {
						if (response.responseId === data.responseId) {
							response.finishedBy = data.finishedBy;
						}
					});
				}
			}
		)(dispatch, getState, undefined);
	};
};

const updateAnswer = (data: {
	userId: string;
	responseId: string;
	answer: Answers;
	questionId: string;
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getAnswer',
			data.responseId,
			(draft: { [key: string]: Answers }) => {
				draft[data.questionId] = data.answer;
			}
		)(dispatch, getState, undefined);

		api.util.updateQueryData(
			'getEditingResponse',
			data.responseId,
			(draft: User[]) => {
				const index = draft.findIndex((user) => user.userId === data.userId);
				if (index !== -1) {
					draft[index].questionId = data.questionId;
				}
			}
		)(dispatch, getState, undefined);
	};
};

const addUserToEditingChecklist = (data: {
	checklistId: string;
	userId: string;
	userFirstName: string;
	userLastName: string;
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getEditingChecklist',
			data.checklistId,
			(
				draft: { userId: string; userFirstName: string; userLastName: string }[]
			) => {
				if (draft.some((user) => user.userId === data.userId)) return;
				draft.push(data);
			}
		)(dispatch, getState, undefined);
	};
};

const removeUserFromEditingChecklist = (data: {
	checklistId: string;
	userId: string;
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getEditingChecklist',
			data.checklistId,
			(
				draft: { userId: string; userFirstName: string; userLastName: string }[]
			) => {
				const index = draft.findIndex((user) => user.userId === data.userId);
				if (index !== -1) {
					draft.splice(index, 1);
				}
			}
		)(dispatch, getState, undefined);
	};
};

const addUserToEditingResponse = (data: {
	responseId: string;
	userId: string;
	userFirstName: string;
	userLastName: string;
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getEditingResponse',
			data.responseId,
			(
				draft: { userId: string; userFirstName: string; userLastName: string }[]
			) => {
				if (draft.some((user) => user.userId === data.userId)) return;
				draft.push(data);
			}
		)(dispatch, getState, undefined);
	};
};

const removeUserFromEditingResponse = (data: {
	responseId: string;
	userId: string;
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getEditingResponse',
			data.responseId,
			(
				draft: { userId: string; userFirstName: string; userLastName: string }[]
			) => {
				const index = draft.findIndex((user) => user.userId === data.userId);
				if (index !== -1) {
					draft.splice(index, 1);
				}
			}
		)(dispatch, getState, undefined);
	};
};

const getCommentsKey = (
	checklistId?: string,
	chapterId?: string,
	questionId?: string
): { entityType: string; entityId: string } => {
	if (checklistId) return { entityType: 'checklist', entityId: checklistId };
	if (chapterId) return { entityType: 'chapter', entityId: chapterId };
	if (questionId) return { entityType: 'question', entityId: questionId };
	return { entityType: 'unknown', entityId: 'unknown' };
};

const addComment = (
	newComment: Comment
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		const key = getCommentsKey(
			newComment.checklistId,
			newComment.chapterId,
			newComment.questionId
		);

		api.util.updateQueryData('getComments', key, (draft) => {
			if (Array.isArray(draft)) {
				draft.push(newComment);
			}
		})(dispatch, getState, undefined);
	};
};

const addReply = (data: {
	commentId: string;
	reply: ReplyType;
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		const key = getCommentsKey(
			data.reply.checklistId,
			data.reply.chapterId,
			data.reply.questionId
		);

		api.util.updateQueryData('getComments', key, (draft: any) => {
			if (Array.isArray(draft)) {
				const comment = draft.find((comment: any) => {
					return comment.commentId === data.commentId;
				});
				if (comment) {
					comment.replies.push(data.reply);
				}
			}
		})(dispatch, getState, undefined);
	};
};

const deleteComment = (data: {
	commentId: string;
	checklistId?: string;
	chapterId?: string;
	questionId?: string;
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		const key = getCommentsKey(
			data.checklistId,
			data.chapterId,
			data.questionId
		);

		api.util.updateQueryData('getComments', key, (draft: Comment[]) => {
			const index = draft.findIndex(
				(comment) => comment.commentId === data.commentId
			);
			if (index !== -1) {
				draft.splice(index, 1);
			}
		})(dispatch, getState, undefined);
	};
};

const deleteReply = (data: {
	commentId: string;
	replyId: string;
	checklistId?: string;
	chapterId?: string;
	questionId?: string;
}): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		const key = getCommentsKey(
			data.checklistId,
			data.chapterId,
			data.questionId
		);

		api.util.updateQueryData('getComments', key, (draft: Comment[]) => {
			const comment = draft.find(
				(comment) => comment.commentId === data.commentId
			);
			if (comment) {
				const replyIndex = comment.replies.findIndex(
					(reply: ReplyType) => reply.replyId === data.replyId
				);
				if (replyIndex !== -1) {
					comment.replies.splice(replyIndex, 1);
				}
			}
		})(dispatch, getState, undefined);
	};
};

const addHistoryEvent = (
	newEvent: Event
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getHistoryFirstPage',
			{ checklistId: newEvent.checklistId, limit: 20 },
			(draft: any) => {
				draft.events.unshift(newEvent);
			}
		)(dispatch, getState, undefined);
	};
};

const canBeMerged = (lastEvent: HistoryEvent, currentEvent: HistoryEvent) => {
	if (
		lastEvent.userId !== currentEvent.userId ||
		Math.abs(
			new Date(lastEvent.timestamp).getTime() -
				new Date(currentEvent.timestamp).getTime()
		) > 60000
	) {
		return false;
	}

	if (
		lastEvent.type === 'chapterCreate' &&
		lastEvent.chapterId !== currentEvent.chapterId
	) {
		return false;
	}

	if (
		lastEvent.type === 'chapterDelete' &&
		lastEvent.chapterId !== currentEvent.chapterId
	) {
		return false;
	}

	if (
		lastEvent.type === 'questionCreate' &&
		lastEvent.chapterId !== currentEvent.chapterId
	) {
		return false;
	}

	if (
		lastEvent.type === 'questionDelete' &&
		lastEvent.chapterId !== currentEvent.chapterId
	) {
		return false;
	}

	if (
		lastEvent.type === 'questionDuplicate' &&
		lastEvent.chapterId !== currentEvent.chapterId
	) {
		return false;
	}

	if (
		lastEvent.type === 'chapterOrder' &&
		currentEvent.type !== 'chapterOrder'
	) {
		return false;
	}

	if (
		lastEvent.type === 'questionOrder' &&
		(currentEvent.type !== 'questionOrder' ||
			lastEvent.chapterId !== currentEvent.chapterId)
	) {
		return false;
	}

	if (
		[
			'singleSelectSettings',
			'multipleSelectSettings',
			'numberSettings',
			'textSettings',
			'mediaSettings',
			'dateSettings',
			'questionRules',
		].includes(lastEvent.type) &&
		(lastEvent.questionId !== currentEvent.questionId ||
			lastEvent.type !== currentEvent.type)
	) {
		return false;
	}

	if (
		lastEvent.type === 'checklistUpdate' &&
		currentEvent.type !== 'checklistUpdate'
	) {
		return false;
	}

	return true;
};

const replaceHistoryEvent = (
	newEvent: HistoryEvent
): ThunkAction<void, RootState, unknown, Action<string>> => {
	return (dispatch, getState) => {
		api.util.updateQueryData(
			'getHistoryFirstPage',
			{ checklistId: newEvent.checklistId, limit: 20 },
			(draft: any) => {
				const lastEvent = draft.events[0];
				if (lastEvent && canBeMerged(lastEvent, newEvent)) {
					draft.events.shift();
					draft.events.unshift(newEvent);
				} else {
					draft.events.unshift(newEvent);
				}
			}
		)(dispatch, getState, undefined);
	};
};

const configureSocket = (
	dispatch: ThunkDispatch<RootState, unknown, Action<string>>
) => {
	const socket = getSocket();
	if (!socket) {
		console.error('Socket not initialized in configureSocket');
		return;
	}

	// Handle WebSocket errors
	socket.on('error', (error) => {
		console.error('WebSocket error:', error);
		if (window.confirm('An error occurred. Do you want to reload the page?')) {
			window.location.reload();
		}
	});

	// Folders
	socket.on('folderCreated', (newFolder: Folder) => {
		dispatch(addFolder(newFolder));
	});
	socket.on('folderUpdated', (updatedFolder: Folder) => {
		dispatch(updateFolder(updatedFolder));
	});
	socket.on('folderDeleted', (folderId: string) => {
		dispatch(removeFolder(folderId));
	});

	// Checklists
	socket.on('checklistCreated', (newChecklist: Checklist) => {
		dispatch(addChecklist(newChecklist));
	});
	socket.on('checklistDuplicated', (newChecklist: Checklist) => {
		dispatch(addChecklist(newChecklist));
	});
	socket.on('checklistDeleted', (checklistDeleted: Checklist) => {
		dispatch(deleteChecklist(checklistDeleted));
	});
	socket.on('checklistUpdated', (updatedChecklist: Checklist) => {
		dispatch(updateChecklist(updatedChecklist));
	});
	socket.on('checklistLastModifiedUpdated', (data) => {
		dispatch(updateChecklistLastModified(data));
	});
	socket.on(
		'checklistMovedToFolder',
		(data: { checklist: Checklist; oldFolderId: string }) => {
			dispatch(moveChecklistToFolder(data.checklist, data.oldFolderId));
		}
	);

	// Chapters
	socket.on('chapterCreated', (newChapter: Chapter) => {
		dispatch(addChapter(newChapter));
	});
	socket.on(
		'chapterDeleted',
		(data: { chapterId: string; checklistId: string }) => {
			dispatch(deleteChapter(data.chapterId, data.checklistId));
		}
	);
	socket.on('chapterUpdated', (updatedChapter: Chapter) => {
		dispatch(updateChapter(updatedChapter));
	});
	socket.on('chaptersReordered', (data) => {
		dispatch(reorderChapters(data));
	});

	// Questions
	socket.on('questionCreated', (newQuestion) => {
		dispatch(addQuestion(newQuestion));
	});
	socket.on('questionDeleted', (data) => {
		dispatch(deleteQuestion(data.questionId, data.chapterId, data.checklistId));
	});
	socket.on('questionUpdated', (updatedQuestion) => {
		dispatch(updateQuestion(updatedQuestion));
	});
	socket.on('questionsReordered', (data) => {
		dispatch(reorderQuestions(data));
	});

	// Responses
	socket.on('responseCreated', (data: Response & { checklistId: string }) => {
		// dispatch(addResponse(data, data.checklistId));
	});
	socket.on('responseUpdated', (data) => {
		dispatch(updateResponse(data));
	});

	// Answers
	socket.on('answerUpdated', (data) => {
		dispatch(updateAnswer(data));
	});

	// Editing
	socket.on('userJoinedChecklist', (data) => {
		dispatch(addUserToEditingChecklist(data));
	});
	socket.on('userLeftChecklist', (data) => {
		dispatch(removeUserFromEditingChecklist(data));
	});
	socket.on('userJoinedResponse', (data) => {
		dispatch(addUserToEditingResponse(data));
	});
	socket.on('userLeftResponse', (data) => {
		dispatch(removeUserFromEditingResponse(data));
	});

	// Comments
	socket.on('commentCreated', (comment: Comment) => {
		dispatch(addComment(comment));
	});
	socket.on('replyAdded', (data: { commentId: string; reply: ReplyType }) => {
		dispatch(addReply(data));
	});
	socket.on(
		'commentDeleted',
		(data: {
			commentId: string;
			checklistId?: string;
			chapterId?: string;
			questionId?: string;
		}) => {
			dispatch(deleteComment(data));
		}
	);
	socket.on(
		'replyDeleted',
		(data: {
			commentId: string;
			replyId: string;
			checklistId?: string;
			chapterId?: string;
			questionId?: string;
		}) => {
			dispatch(deleteReply(data));
		}
	);

	// History
	socket.on('historyEventAdded', (newEvent: Event) => {
		dispatch(addHistoryEvent(newEvent));
	});

	socket.on('historyEventMerged', (newEvent: HistoryEvent) => {
		dispatch(replaceHistoryEvent(newEvent));
	});
};

export const socketMiddleware: Middleware<{}, RootState> = (storeAPI) => {
	const typedDispatch = storeAPI.dispatch as ThunkDispatch<
		RootState,
		unknown,
		Action<string>
	>;

	return (next) => (action: any) => {
		if (action.type === 'user/setAuthenticated' && action.payload === true) {
			if (!isSocketConnected()) {
				initializeSocket(() => {
					configureSocket(typedDispatch);
				});
			}
		}

		return next(action);
	};
};

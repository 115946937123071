import styles from './Avatar.module.scss';

const colors = [
	'#FAB1A0',
	'#A29BFE',
	'#7FE1D9',
	'#A4B2D5',
	'#3CCEDC',
	'#FD67B1',
	'#FAA95E',
	'#8BABF1',
	'#DC92AD',
	'#8AB85D',
	'#90D8B2',
	'#E396DF',
	'#ED8795',
	'#00BF7D',
	'#8C6583',
	'#D3C96C',
	'#B884D1',
	'#52B5B4',
	'#5D5BBD',
	'#E77A6C',
];

function hashStringToNumber(str: string) {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
}

export function getColorForId(id: string) {
	const hash = hashStringToNumber(String(id));
	const colorIndex = Math.abs(hash) % colors.length;
	return colors[colorIndex];
}

export default function Avatar({
	name,
	id,
	small = false,
	medium = false,
	big = false,
	column = false,
	color = 'default',
}: {
	name: string;
	id?: string | number;
	small?: boolean;
	medium?: boolean;
	big?: boolean;
	column?: boolean;
	color?: string;
}) {
	const splittedName = name.split(' ');
	const initials = `${splittedName[0][0]}${
		splittedName[1] ? splittedName[1][0] : ''
	}`.toUpperCase();

	const bgColor = color === 'grey' ? '#777D90' : getColorForId(name);

	return (
		<div
			className={`${styles.avatar} ${small ? styles.small : ''} ${
				medium ? styles.medium : ''
			} ${big ? styles.big : ''} ${initials === '' ? styles.noBg : ''} ${
				column ? styles.column : ''
			}`}
			style={{ ...(initials !== '' && { backgroundColor: bgColor }) }}
		>
			{initials}
		</div>
	);
}

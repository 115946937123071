import { useMemo, useState, useEffect, useRef } from 'react';
import { getSocket } from '../store/socket';
import InvitePopup from './Checklist/InvitePopup/InvitePopup';
import Checklist from './Checklist/Checklist';
import { useDeviceType } from '../useDeviceType';
import { useNavigation } from '../NavigationContext';
import { Checklist as ChecklistType, Response, Question } from '../sharedTypes';
import {
	useGetChecklistQuery,
	useGetAnswerQuery,
	useGetResponsesQuery,
} from '../store/api';
import { ReactComponent as MailIcon } from './icons/mail.svg';
import { ReactComponent as ArrowBackIcon } from './icons/arrow_back.svg';
import NoResponsePage from './Response/NoResponsePage';
import Comments from './Comments';
import ResponsesMenu from './Response/ResponsesMenu';
import QuestionMenu from './Response/QuestionMenu';
import ScrollableMobileResponse from './Response/ScrollableMobileResponse';
import ResponsePage from './Response/ResponsePage';
import Navbar from './Navbar/Navbar';
import PdfGenerator from './Pdf/PdfGenerator';
import config from '../config';
import styles from './Checklist/Checklist.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { applyRulesToResponse } from '../utils/applyRules';
import LoadingFullScreen from './LoadingFullScreen/LoadingFullScreen';

const ResponseChecklist = ({
	id,
	iframe,
	response,
	refetchResponses,
}: {
	id: string;
	iframe?: boolean;
	response: Response & { folderId: string };
	refetchResponses: () => void;
}) => {
	const { isTypeOrLarger } = useDeviceType();
	const { navigation, setNavigation } = useNavigation();
	const { data } = useGetAnswerQuery(response.responseId, {
		skip: !response.responseId,
	});
	const isFirstRender = useRef(true);

	const responseWithRulesApplied = useMemo(() => {
		if (!data) return response;
		return applyRulesToResponse(response, data);
	}, [response, data]);

	useEffect(() => {
		if (
			isFirstRender.current &&
			isTypeOrLarger('md') &&
			responseWithRulesApplied?.state?.chapters?.length > 0
		) {
			const firstChapter = responseWithRulesApplied.state.chapters[0];
			if ((firstChapter.questions || []).length > 0) {
				setNavigation(`checklist_${response.state.checklistId}`);
			}
			isFirstRender.current = false;
		}
	}, [isTypeOrLarger, responseWithRulesApplied, setNavigation, response]);

	if (!data) return null;

	return (
		<div className={styles.responseContainer}>
			<div className={styles.flex}>
				<Checklist
					id={id}
					iframe={iframe}
					data={responseWithRulesApplied.state as ChecklistType}
					values={data}
					responseId={response.responseId}
					mode="response"
					navigation={navigation}
					setNavigation={setNavigation}
					refetchResponses={refetchResponses}
				/>
			</div>
		</div>
	);
};

const ResponsesChecklist = ({
	id,
	checklist,
	iframe,
	missionDetails = '',
	response,
	responses,
	refetchResponses,
	hasResponseId,
	setHasResponseId,
}: {
	id: string;
	checklist?: ChecklistType;
	iframe?: boolean;
	missionDetails: string;
	response: Response & { folderId: string; responseId: string };
	responses: Response[];
	refetchResponses: () => void;
	hasResponseId: boolean;
	setHasResponseId: (hasResponseId: boolean) => void;
}) => {
	const { navigation, setNavigation } = useNavigation();
	const { data } = useGetAnswerQuery(response.responseId, {
		skip: !response.responseId,
	});
	const isIntegrated = useSelector(
		(state: RootState) => state.user.isIntegrated
	);
	const [commentsOn, setCommentsOn] = useState(false);
	const [openInvitePopup, setOpenInvitePopup] = useState(false);
	const [isAutoNavigationDisabled, setIsAutoNavigationDisabled] =
		useState(true);
	const [copied, setCopied] = useState(false);
	const [selectedComment, setSelectedComment] = useState<{
		roomId: string;
		entityType: 'checklist' | 'chapter' | 'question';
		entityId: string;
	}>({
		roomId: `checklist-${response.state?.checklistId}`,
		entityType: 'checklist',
		entityId: response.state?.checklistId,
	});

	useEffect(() => {
		if (navigation.startsWith('question')) {
			setSelectedComment({
				roomId: navigation,
				entityType: 'question',
				entityId: navigation.split('_')[2],
			});
		}
	}, [navigation, response]);

	useEffect(() => {
		getSocket()?.emit('joinResponse', id);
	}, [id]);

	const responseWithRulesApplied = useMemo(() => {
		if (!data) return response;
		return applyRulesToResponse(response, data);
	}, [response, data]);

	const handleCopyLink = () => {
		const newInviteLink = `${config.baseUrl
			.replace('/api', '')
			.replace('localhost:3000', 'localhost:3001')}/?response=${
			response.responseId
		}`;
		navigator.clipboard.writeText(newInviteLink);
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	useEffect(() => {
		if (data && responses.length > 0) {
			setHasResponseId(false);
		}
	}, [data, responses, setHasResponseId]);

	return (
		<div className={styles.responseContainer}>
			<>
				<Navbar
					iframe={iframe}
					before={
						isIntegrated || iframe ? null : (
							<button
								className={styles.noBorderButton}
								onClick={() => setNavigation(`folder_${response.folderId}`)}
							>
								<ArrowBackIcon />
							</button>
						)
					}
					after={
						<div className={styles.flex}>
							{!isIntegrated && !iframe && (
								<button
									onClick={() => {
										if (response && response.responseId) {
											handleCopyLink();
										} else {
											setOpenInvitePopup(true);
										}
									}}
									className={styles.primaryButton}
								>
									<MailIcon />
									{response && response.responseId && copied
										? 'Copié !'
										: 'Inviter à remplir'}
								</button>
							)}
							{response.responseId && data && (
								<PdfGenerator
									id={id}
									response={response as any}
									checklist={responseWithRulesApplied.state as ChecklistType}
									currentAnswers={data}
									missionDetails={missionDetails}
								/>
							)}
						</div>
					}
				/>
				<div
					style={{ height: 'calc(100% - 4.5rem)', gap: 0 }}
					className={styles.flex}
				>
					{!isIntegrated && !iframe && (
						<ResponsesMenu
							checklist={checklist}
							checklistId={id}
							setNavigation={setNavigation}
						/>
					)}
					{data && responses.length > 0 ? (
						<>
							{navigation.startsWith('question') ? (
								<ScrollableMobileResponse
									id={id}
									data={responseWithRulesApplied.state as ChecklistType}
									values={data}
									responseId={response.responseId}
									isAutoNavigationDisabled={isAutoNavigationDisabled}
									setIsAutoNavigationDisabled={setIsAutoNavigationDisabled}
									setCommentsOn={setCommentsOn}
									commentsOn={commentsOn}
								/>
							) : (
								<ResponsePage
									checklist={responseWithRulesApplied.state as ChecklistType}
									iframe={iframe}
									checklistId={id}
									response={response}
									responseIndex={responses.findIndex(
										(r) => r.responseId === response.responseId
									)}
									firstQuestion={
										responseWithRulesApplied.state.chapters?.[0]
											?.questions?.[0] as Question
									}
									currentAnswers={data}
									setNavigation={setNavigation}
								/>
							)}
						</>
					) : (
						<NoResponsePage
							setOpenInvitePopup={setOpenInvitePopup}
							checklist={checklist}
							openComments={() => {
								setCommentsOn(true);
								setSelectedComment({
									roomId: `checklist-${checklist?.checklistId}`,
									entityType: 'checklist',
									entityId: checklist?.checklistId || '',
								});
							}}
						/>
					)}
					{openInvitePopup && (
						<InvitePopup
							close={() => {
								setOpenInvitePopup(false);
								refetchResponses();
							}}
						/>
					)}
					{commentsOn ? (
						<Comments
							roomId={selectedComment.roomId}
							entityType={selectedComment.entityType}
							entityId={selectedComment.entityId}
							close={() => setCommentsOn(false)}
							noBorder
						/>
					) : (
						!navigation.startsWith('checklist') &&
						data && (
							<QuestionMenu
								data={responseWithRulesApplied.state as any}
								values={data}
								setIsAutoNavigationDisabled={setIsAutoNavigationDisabled}
							/>
						)
					)}
				</div>
			</>
			{!data && hasResponseId && <LoadingFullScreen />}
		</div>
	);
};

export default function ChecklistContainer({
	id,
	response,
	hasResponseId,
	setHasResponseId,
}: {
	id: string;
	response?: Response;
	hasResponseId: boolean;
	setHasResponseId: (hasResponseId: boolean) => void;
}) {
	const { isTypeOrLarger } = useDeviceType();
	const { navigation, setNavigation, responseMode } = useNavigation();
	const { data } = useGetChecklistQuery(id);
	const { data: responsesData, refetch } = useGetResponsesQuery({ id });
	const responses = responsesData?.data || [];
	const iframe = localStorage.getItem('isIframe') === 'true';
	const iframeMission = localStorage.getItem('iframeMission');
	const hasIframeMission = iframeMission !== null && iframeMission !== '';

	return (
		<div style={{ height: '100%' }}>
			{responseMode ? (
				isTypeOrLarger('md') ? (
					<ResponsesChecklist
						id={id}
						iframe={iframe}
						missionDetails={hasIframeMission ? iframeMission : ''}
						response={
							{ ...response, folderId: data?.folderId || '' } as Response & {
								folderId: string;
							}
						}
						checklist={data as ChecklistType | undefined}
						responses={responses || []}
						refetchResponses={refetch}
						hasResponseId={hasResponseId}
						setHasResponseId={setHasResponseId}
					/>
				) : (
					<ResponseChecklist
						id={id}
						iframe={iframe}
						response={
							{ ...response, folderId: data?.folderId || '' } as Response & {
								folderId: string;
							}
						}
						refetchResponses={refetch}
					/>
				)
			) : (
				<>
					{data && (
						<Checklist
							id={id}
							iframe={iframe}
							data={{
								...data.state,
								folderId: data.folderId,
								lastModified: {
									userFirstName: data.lastModified?.userFirstName,
									userLastName: data.lastModified?.userLastName,
									timestamp: new Date(data.lastModified?.timestamp || ''),
								},
							}}
							mode="edit"
							navigation={navigation}
							setNavigation={setNavigation}
							refetchResponses={refetch}
						/>
					)}
				</>
			)}
		</div>
	);
}

import { createApiWithBaseQuery } from './apiBase';

export const userApi = createApiWithBaseQuery('userApi').injectEndpoints({
	endpoints: (builder) => ({
		signup: builder.mutation({
			query: ({ email, password, firstName, lastName }) => ({
				url: '/auth/signup',
				method: 'POST',
				body: { email, password, firstName, lastName },
			}),
		}),
		login: builder.mutation({
			query: ({ email, password }) => ({
				url: '/auth/login',
				method: 'POST',
				body: { email, password },
			}),
		}),
		refreshToken: builder.mutation({
			query: (refreshToken) => ({
				url: '/auth/refresh-token',
				method: 'POST',
				body: { refreshToken },
			}),
		}),
		me: builder.query({
			query: () => ({
				url: '/auth/me',
				method: 'GET',
			}),
		}),
		setPassword: builder.mutation({
			query: ({ newPassword }) => ({
				url: '/auth/password',
				method: 'PUT',
				body: { newPassword },
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
					'x-token-type': 'iframe',
				},
			}),
		}),
		updateUserName: builder.mutation({
			query: ({ firstName, lastName }) => ({
				url: '/auth/name',
				method: 'PUT',
				body: { firstName, lastName },
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}),
		}),
	}),
});

export const {
	useSignupMutation,
	useLoginMutation,
	useRefreshTokenMutation,
	useMeQuery,
	useSetPasswordMutation,
	useUpdateUserNameMutation,
} = userApi;

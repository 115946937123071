import { useMemo } from 'react';
import {
	Chapter as ChapterType,
	Answer as Answers,
} from '../../../sharedTypes';
import { ReactComponent as FilledCheckIcon } from '../../icons/check_green.svg';
import styles from './QuestionSelector.module.scss';

export default function ProgressBar({
	isPdf = false,
	chapter,
	chapters,
	answers,
	currentQuestionId,
	noPadding,
	width,
}: {
	isPdf?: boolean;
	chapter?: ChapterType;
	chapters?: ChapterType[];
	answers: { [key: string]: Answers };
	currentQuestionId?: string;
	noPadding?: boolean;
	width?: string;
}) {
	const answeredRequiredQuestions = useMemo(() => {
		if (chapters) {
			return chapters.reduce((total, chapter) => {
				return (
					total +
					(chapter.questions || []).filter(
						(question) =>
							question.required &&
							question.questionId &&
							answers?.[question.questionId]
					).length
				);
			}, 0);
		} else if (chapter) {
			return (chapter.questions || []).filter(
				(question) =>
					question.required &&
					question.questionId &&
					answers?.[question.questionId]
			).length;
		}
		return 0;
	}, [chapter, chapters, answers]);

	const totalRequiredQuestions = useMemo(() => {
		if (chapters) {
			return chapters.reduce((total, chapter) => {
				return (
					total + (chapter.questions || []).filter((q) => q.required).length
				);
			}, 0);
		} else if (chapter) {
			return (chapter.questions || []).filter((q) => q.required).length;
		}
		return 0;
	}, [chapter, chapters]);

	const currentQuestionIndex = useMemo(() => {
		if (chapter && currentQuestionId) {
			return (
				(chapter.questions || []).findIndex(
					(question) => question.questionId === currentQuestionId
				) + 1
			);
		}
		return 0;
	}, [chapter, currentQuestionId]);

	const progressPercentage = useMemo(() => {
		let progressPercentage = 0;

		if (currentQuestionId) {
			progressPercentage =
				currentQuestionIndex > 0
					? (currentQuestionIndex / totalRequiredQuestions) * 100
					: 0;
		} else {
			progressPercentage =
				totalRequiredQuestions > 0
					? (answeredRequiredQuestions / totalRequiredQuestions) * 100
					: 0;
		}
		return progressPercentage;
	}, [
		answeredRequiredQuestions,
		totalRequiredQuestions,
		currentQuestionId,
		currentQuestionIndex,
	]);

	const remainingQuestions = useMemo(() => {
		return totalRequiredQuestions - answeredRequiredQuestions;
	}, [totalRequiredQuestions, answeredRequiredQuestions]);

	return (
		<div
			className={`${styles.progressContainer} ${
				isPdf ? styles.pdfProgress : ''
			} ${currentQuestionId ? styles.pxValues : ''} ${
				noPadding ? styles.noPadding : ''
			}`}
		>
			{progressPercentage === 100 && noPadding ? (
				<div className={`${styles.flex} ${chapter ? styles.smallIcons : ''}`}>
					{!isPdf && <FilledCheckIcon />}
					<div
						className={`${styles.tabBarItems} ${isPdf ? styles.pdfText : ''}`}
					>
						{chapter ? 'Terminé' : 'Complète'}
					</div>
					{isPdf && <FilledCheckIcon />}
				</div>
			) : (
				<>
					{!currentQuestionId && (
						<div
							className={styles.tabBarItems}
						>{`${remainingQuestions} questions restantes`}</div>
					)}
					<div
						className={styles.progressBarBackground}
						style={{ width: width }}
					>
						<div
							className={styles.progressBar}
							style={{ width: `${progressPercentage}%` }}
						></div>
					</div>
					{currentQuestionId && currentQuestionIndex > 0 && (
						<div className={styles.tabBarItems}>
							{currentQuestionIndex} sur {totalRequiredQuestions} questions
						</div>
					)}
				</>
			)}
		</div>
	);
}

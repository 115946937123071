import {
	useState,
	useCallback,
	useEffect,
	useRef,
	useLayoutEffect,
} from 'react';
import { VariableSizeList as List } from 'react-window';
import styles from './InfiniteScroll.module.scss';

export interface PaginatedResponse<T> {
	count: number;
	next: string | null;
	previous: string | null;
	results: T[];
}

interface InfiniteScrollProps<T> {
	useQuery: (params?: any) => {
		data?: PaginatedResponse<T>;
		isFetching: boolean;
		isSuccess: boolean;
	};
	row: (props: {
		index: number;
		style: React.CSSProperties;
		data: T[];
		setItemSize: (index: number, size: number) => void;
	}) => React.ReactElement | null;
	itemSize: number;
	queryParams: any;
	itemClicked?: number;
	undoItemClicked?: () => void;
	onLoadMore: () => void;
}

function InfiniteScroll<T>({
	useQuery,
	row: Row,
	itemSize: defaultItemSize,
	queryParams,
	itemClicked,
	undoItemClicked,
	onLoadMore,
}: InfiniteScrollProps<T>) {
	const [items, setItems] = useState<T[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const listRef = useRef<HTMLDivElement>(null);
	const [listHeight, setListHeight] = useState(400);
	const [itemSizes, setItemSizes] = useState<{ [key: number]: number }>({});

	const { data, isFetching, isSuccess } = useQuery();

	useLayoutEffect(() => {
		if (listRef.current) {
			const height = listRef.current.offsetHeight;
			setListHeight(height);
		}
	}, []);

	useEffect(() => {
		if (isSuccess && data?.results) {
			setItems((prev) => {
				const newItems = data.results.filter(
					(item: T) =>
						!prev.some(
							(existingItem: any) =>
								existingItem.responseId === (item as any).responseId
						)
				);

				if (newItems.length === 0) return prev;
				return [...prev, ...newItems];
			});
			setIsLoading(false);
		}
	}, [data, isSuccess, items]);

	useEffect(() => {
		if (itemClicked && undoItemClicked) {
			undoItemClicked();
		}
	}, [itemClicked, undoItemClicked]);

	const loadMore = useCallback(() => {
		if (!isLoading && !isFetching && data?.next) {
			setIsLoading(true);
			onLoadMore();
		}
	}, [isLoading, isFetching, data?.next, onLoadMore]);

	const setItemSize = useCallback((index: number, size: number) => {
		setItemSizes((prev) => {
			if (prev[index] === size) return prev;
			return { ...prev, [index]: size };
		});
	}, []);

	const getItemSize = useCallback(
		(index: number) => {
			return itemSizes[index] || defaultItemSize;
		},
		[itemSizes, defaultItemSize]
	);

	const RowRenderer = useCallback(
		({ index, style }: any) => (
			<Row index={index} style={style} data={items} setItemSize={setItemSize} />
		),
		[Row, items, setItemSize]
	);

	if (items.length === 0 && !isLoading && !isFetching && isSuccess) {
		return <div className={styles.empty}>Aucun résultat</div>;
	}

	return (
		<div ref={listRef} style={{ height: '100%' }}>
			<List
				className={styles.list}
				height={listHeight}
				itemCount={items.length || 0}
				itemSize={getItemSize}
				width="100%"
				onItemsRendered={({ visibleStopIndex }) => {
					if (visibleStopIndex >= items.length - 3) {
						loadMore();
					}
				}}
			>
				{RowRenderer}
			</List>
			{(isLoading || isFetching) && (
				<div className={styles.loading}>
					<span>Chargement...</span>
				</div>
			)}
		</div>
	);
}

export default InfiniteScroll;

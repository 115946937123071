import { useEffect, useMemo, useState } from 'react';
import { useDeviceType } from 'useDeviceType';
import { useNavigation } from '../../NavigationContext';
import { getSocket } from '../../store/socket';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
// Api
import {
	useGetEditingChecklistQuery,
	useGetEditingResponseQuery,
	useGetResponsesQuery,
} from '../../store/api';
import { useMeQuery } from '../../store/userApi';
// Components
import Avatar from '../Avatar/Avatar';
import Chapter from './Chapter';
import Comments from '../Comments';
import CoverImage from 'components/shared/CoverImage/CoverImage';
import DraggableNavigation from './DraggableNavigation';
import InvitePopup from './InvitePopup/InvitePopup';
import LateralPannel from './LateralPanel';
import Navbar from 'components/Navbar/Navbar';
import Question from './Question';
import TitleInput from '../TitleInput/TitleInput';
import History from './History/History';
import { Upload } from '../Upload';
// Icons
import { ReactComponent as MailIcon } from '../icons/mail.svg';
import { ReactComponent as UndoIcon } from '../icons/undo.svg';
import { ReactComponent as RedoIcon } from '../icons/redo.svg';
import { ReactComponent as ArrowDownIcon } from '../icons/arrow_down.svg';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
import { ReactComponent as PreviousIcon } from '../icons/previous.svg';
import { ReactComponent as NextIcon } from '../icons/next.svg';
import { ReactComponent as TranslateIcon } from '../icons/translate.svg';
import { ReactComponent as ExclamationMarkIcon } from '../icons/exclamation_mark.svg';
import { ReactComponent as PlayCircle } from '../icons/play_circle.svg';
import { ReactComponent as PlayCircleFilled } from '../icons/play_circle_filled.svg';
import { ReactComponent as CheckIcon } from '../icons/check_circle.svg';
import { ReactComponent as LeftArrowIcon } from '../icons/arrow_back.svg';
import { ReactComponent as HistoryIcon } from '../icons/history.svg';
// Styles
import styles from './Checklist.module.scss';
// Types
import {
	Checklist as ChecklistType,
	Chapter as ChapterType,
	Answer as Answers,
	Question as QuestionType,
	Response,
} from '../../sharedTypes';

export default function Checklist({
	id,
	iframe = false,
	responseId,
	mode,
	data,
	values,
	navigation,
	setNavigation,
	refetchResponses = () => {},
}: {
	id: string;
	iframe?: boolean;
	responseId?: string;
	mode: 'edit' | 'response';
	data: ChecklistType;
	values?: { [key: string]: Answers };
	navigation: string;
	setNavigation: (navigation: string) => void;
	refetchResponses?: () => void;
}) {
	const user = useSelector((state: RootState) => state.user.userData);
	const { setOpenedResponse, setOpenedChecklistId } = useNavigation();
	const { isTypeOrLarger } = useDeviceType();
	const [panelMode, setPanelMode] = useState<
		'comments' | 'views' | 'history' | null
	>(null);
	const [openInvitePopup, setOpenInvitePopup] = useState(false);

	const { data: meData } = useMeQuery({});
	const { data: editingChecklist } = useGetEditingChecklistQuery(id, {
		skip: mode === 'response',
	});
	const { data: editingResponse } = useGetEditingResponseQuery(
		responseId || '',
		{
			skip: mode === 'edit',
		}
	);
	const { data: responsesData } = useGetResponsesQuery({ id });
	const responses = responsesData?.data || [];

	const [selectedComment, setSelectedComment] = useState<{
		roomId: string;
		entityType: 'checklist' | 'chapter' | 'question';
		entityId: string;
	}>({
		roomId: `checklist-${data.checklistId}`,
		entityType: 'checklist',
		entityId: data.checklistId,
	});

	useEffect(() => {
		document.title = `${data.title}`;
	}, [data]);

	useEffect(() => {
		if (navigation !== 'checklist') {
			setSelectedComment({
				roomId: navigation,
				entityType: navigation.split('_')[0] as 'chapter' | 'question',
				entityId:
					navigation.split('_')[navigation.startsWith('question') ? 2 : 1],
			});
		} else {
			setSelectedComment({
				roomId: `checklist-${data.checklistId}`,
				entityType: 'checklist',
				entityId: data.checklistId,
			});
		}
	}, [navigation, data.checklistId]);

	useEffect(() => {
		// if (mode === 'response' || !responseId) return;
		const roomId = mode === 'edit' ? id : responseId;
		const joinEvent = mode === 'edit' ? 'joinChecklist' : 'joinResponse';
		// const leaveEvent = mode === 'edit' ? 'leaveChecklist' : 'leaveResponse';

		getSocket()?.emit(joinEvent, roomId);
		// return () => {
		// 	getSocket()?.emit(leaveEvent, roomId);
		// };
	}, [id, responseId, mode]);

	const handleUndo = () => {
		if (mode === 'edit') {
			getSocket()?.emit('undo', { checklistId: id });
		} else {
			getSocket()?.emit('undoAnswer', { responseId: responseId });
		}
	};

	const handleRedo = () => {
		if (mode === 'edit') {
			getSocket()?.emit('redo', { checklistId: id });
		} else {
			getSocket()?.emit('redoAnswer', { responseId: responseId });
		}
	};

	const question = useMemo(() => {
		const chapter = data.chapters.find((chapter: ChapterType) =>
			chapter.questions?.some(
				(question: QuestionType) =>
					question.questionId === navigation.split('_')[2]
			)
		);
		if (!chapter) return null;
		return (chapter as ChapterType).questions?.find(
			(question: QuestionType) =>
				question.questionId === navigation.split('_')[2]
		);
	}, [data, navigation]);

	const isLastQuestion = useMemo(() => {
		const allQuestions = data.chapters.flatMap((chapter) => chapter.questions);
		const currentQuestionIndex = allQuestions?.findIndex(
			(q: any) => q?.questionId === navigation.split('_')[2]
		);
		return currentQuestionIndex === allQuestions.length - 1;
	}, [data, navigation]);

	return (
		<div className={styles[mode]} style={{ position: 'relative' }}>
			{mode === 'edit' ? (
				<>
					<Navbar
						border
						iframe={iframe}
						before={
							iframe ? null : (
								<button
									onClick={() => {
										if (setOpenedChecklistId) {
											setOpenedChecklistId(null);
											setNavigation(`folder_${data.folderId}`);
										}
									}}
									className={styles.noBorderButton}
								>
									<LeftArrowIcon />
								</button>
							)
						}
						after={
							<div className={styles.flex}>
								{editingChecklist
									?.filter((user) => user.userId !== meData?.userId)
									.map((user) => (
										<Avatar
											name={`${user.userFirstName} ${user.userLastName}`}
											key={user.userId}
										/>
									))}
								{editingResponse
									?.filter((user) => user.userId !== meData?.userId)
									.map((user) => (
										<Avatar
											small
											name={`${user.userFirstName} ${user.userLastName}`}
											key={user.userId}
										/>
									))}
								<div className={styles.verticalSeparator} />
								<button className={styles.noBorderButton} onClick={handleUndo}>
									<UndoIcon />
								</button>
								<button className={styles.noBorderButton} onClick={handleRedo}>
									<RedoIcon />
								</button>
								<button
									onClick={() => setOpenInvitePopup(true)}
									className={styles.primaryButton}
								>
									<MailIcon />
									Inviter à remplir
								</button>
								{openInvitePopup && (
									<InvitePopup
										close={() => {
											setOpenInvitePopup(false);
											refetchResponses();
										}}
									/>
								)}
							</div>
						}
					/>

					<div className={styles.content}>
						<DraggableNavigation
							iframe={iframe}
							checklistId={id}
							title={data.title || 'Untitled Checklist'}
							checklist={data}
							firstResponse={responses?.[0]}
							setCommentMode={() => {
								setPanelMode(null);
								setSelectedComment({
									roomId: `checklist-${data.checklistId}`,
									entityType: 'checklist',
									entityId: data.checklistId,
								});
							}}
						/>
						{navigation.startsWith('checklist') && (
							<div className={styles.checklist}>
								<TitleInput
									mode={'title'}
									placeholder="Saisir le titre de la checklist"
									title={data.title}
									onChange={(value) =>
										getSocket()?.emit('updateChecklist', {
											checklistId: id,
											title: value,
											coverImage: data.coverImage || '',
										})
									}
								/>
								<div className={styles.separator} />
								<div className={styles.imgContainer}>
									{data.coverImage ? (
										<img src={data.coverImage} alt="cover" />
									) : (
										<CoverImage id={id} width="100%" height="100%" />
									)}
								</div>
								<Upload
									uploadId={id}
									value={data.coverImage || []}
									onChange={(value) =>
										getSocket()?.emit('updateChecklist', {
											checklistId: id,
											coverImage: value,
											title: data.title || '',
										})
									}
									mode="photo"
									buttonLabel="Ajouter une image de couverture"
									hide
									replace
								/>
								<button
									onClick={() => setPanelMode('history')}
									className={styles.greyButton}
								>
									<HistoryIcon />
									Historique
								</button>
							</div>
						)}
						{data?.chapters?.map(
							(chapter: ChapterType) =>
								navigation.includes(chapter.chapterId) && (
									<div key={chapter.chapterId} className={styles.settings}>
										<Chapter
											key={chapter.chapterId}
											chapter={chapter}
											checklistId={id}
											responseId={responseId}
											mode={mode}
											navigation={navigation}
											setNavigation={setNavigation}
											values={values}
											checklist={data}
											panelMode={panelMode}
											setPanelMode={setPanelMode}
										/>
									</div>
								)
						)}
						{panelMode === 'history' && navigation.startsWith('checklist') ? (
							<History checklistId={id} close={() => setPanelMode(null)} />
						) : panelMode === 'comments' ? (
							<Comments
								roomId={selectedComment.roomId}
								entityType={selectedComment.entityType}
								entityId={selectedComment.entityId}
								close={() => setPanelMode(null)}
							/>
						) : (
							navigation.startsWith('question') && (
								<LateralPannel
									checklist={data}
									id={id}
									navigation={navigation}
									close={() => setPanelMode(null)}
								/>
							)
						)}
					</div>
				</>
			) : (
				<>
					{!isTypeOrLarger('md') ? (
						<>
							<div className={styles.navigation}>
								<div
									className={`${styles.header} ${iframe ? styles.flexEnd : ''}`}
								>
									{!user?.email.includes('exetanch') && !iframe && (
										<ArrowDownIcon
											onClick={() => setNavigation(`folder_${data.folderId}`)}
										/>
									)}
									<ShareIcon />
								</div>
								<div className={styles.spacedFlex}>
									<PreviousIcon
										onClick={() => {
											if (responses) {
												const index = responses.findIndex(
													(response) => response.responseId === responseId
												);
												if (index > 0) {
													setOpenedResponse &&
														setOpenedResponse(responses[index - 1] as Response);
												}
											}
										}}
									/>
									{data.coverImage ? (
										<img src={data.coverImage} alt="cover" />
									) : (
										<CoverImage id={id} width="100%" height="2.5rem" />
									)}
									<NextIcon
										onClick={() => {
											if (responses) {
												const index = responses.findIndex(
													(response) => response.responseId === responseId
												);
												if (index < responses.length - 1) {
													setOpenedResponse &&
														setOpenedResponse(responses[index + 1] as Response);
												}
											}
										}}
									/>
								</div>
								<div className={styles.title}>
									<h4>{data.title || 'Untitled Checklist'}</h4>
									{!iframe && (
										<div className={styles.tabBarItems}>
											{/* Pas encore commencée */}( Réponse n={' '}
											{(responses?.findIndex(
												(response) => response.responseId === responseId
											) || 0) + 1}
											)
										</div>
									)}
								</div>
								<div className={styles.navButton}>
									<button className={styles.greyButton}>
										<TranslateIcon /> Traduire
									</button>
									<button className={styles.greyButton}>
										<ExclamationMarkIcon />
										Signaler un problème
									</button>
								</div>
							</div>
							<div className={styles.content}>
								<h5>Chapitres</h5>
								{data?.chapters
									?.sort((a: ChapterType, b: ChapterType) => a.index - b.index)
									?.map((chapter: ChapterType) => (
										<div
											onClick={() =>
												setNavigation(
													`question_${chapter.chapterId}_${chapter.questions?.[0].questionId}`
												)
											}
											className={styles.spacedFlex}
											key={chapter.chapterId}
										>
											<div className={styles.column}>
												<div className={styles.button}>{chapter.title}</div>
												<div className={styles.tabBarItems}>
													Chapitre{' '}
													{data.chapters.findIndex(
														(thisChapter: ChapterType) =>
															thisChapter.chapterId === chapter.chapterId
													) + 1}
												</div>
											</div>
											{chapter.questions?.every(
												(question) => values?.[question.questionId]
											) ? (
												<CheckIcon />
											) : (
												<PlayCircleFilled />
											)}
										</div>
									))}
							</div>
							<div className={styles.footer}>
								<button
									onClick={() => {
										if (
											Array.isArray(data.chapters) &&
											data.chapters.length > 0
										) {
											const firstUnansweredQuestionId = data.chapters
												.flatMap(
													(chapter: ChapterType) => chapter.questions || []
												)
												.find(
													(question) =>
														question.questionId &&
														!values?.[question.questionId]
												)?.questionId;

											if (firstUnansweredQuestionId) {
												setNavigation(
													`question_${
														firstUnansweredQuestionId.split('_')[0]
													}_${firstUnansweredQuestionId}`
												);
											} else {
												const firstChapter = (
													data.chapters as ChapterType[]
												)[0];
												if (
													firstChapter &&
													Array.isArray(firstChapter.questions) &&
													firstChapter.questions.length > 0
												) {
													const firstQuestionId =
														firstChapter.questions[0].questionId;
													if (firstQuestionId) {
														setNavigation(
															`question_${firstChapter.chapterId}_${firstQuestionId}`
														);
													}
												}
											}
										}
									}}
									className={styles.primaryButton}
								>
									{Array.isArray(data.chapters) &&
									data.chapters.length > 0 &&
									data.chapters
										.flatMap((chapter: ChapterType) => chapter.questions || [])
										.some(
											(question) =>
												question.questionId && values?.[question.questionId]
										)
										? 'Reprendre'
										: 'Commencer'}
									<PlayCircle />
								</button>
							</div>
						</>
					) : (
						<div className={styles.emptyPlaceholder} />
					)}
					{navigation.startsWith('question') && (
						<Question
							question={question as QuestionType}
							checklistId={id}
							responseId={responseId}
							navigation={navigation}
							setNavigation={setNavigation}
							mode={'response'}
							values={values}
							checklist={data}
							panelMode={panelMode}
							setPanelMode={setPanelMode}
							isLastQuestion={isLastQuestion}
						/>
					)}
				</>
			)}
		</div>
	);
}

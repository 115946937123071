import React, { useState } from 'react';
import { useSetPasswordMutation } from '../../store/userApi';
import styles from './LoginForm.module.scss';

function SetPasswordForm() {
	const [newPassword, setNewPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [setPassword, { isLoading }] = useSetPasswordMutation();
	const [errorMessage, setErrorMessage] = useState<string>('');

	const handleSetPassword = async (e: React.FormEvent) => {
		e.preventDefault();
		setErrorMessage('');

		if (newPassword !== confirmPassword) {
			setErrorMessage('Les mots de passe ne correspondent pas');
			return;
		}

		if (newPassword.length < 8) {
			setErrorMessage('Le mot de passe doit contenir au moins 8 caractères');
			return;
		}

		try {
			const response = await setPassword({ newPassword }).unwrap();
			if (response.message === 'Mot de passe mis à jour avec succès') {
				window.history.pushState(null, '', '/');
				window.dispatchEvent(new Event('popstate'));
			}
		} catch (err: any) {
			setErrorMessage(err.data?.error || 'Une erreur est survenue');
			console.error('Set password error:', err);
		}
	};

	return (
		<form onSubmit={handleSetPassword} className={styles.register}>
			<p>
				Si vous vous connectez habituellement via un token externe, vous pouvez
				créer un mot de passe pour vous connecter directement.
			</p>

			<div>
				<label htmlFor="new-password">Nouveau mot de passe:</label>
				<input
					type="password"
					id="new-password"
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
					required
					minLength={8}
				/>
			</div>

			<div>
				<label htmlFor="confirm-password">Confirmer le mot de passe:</label>
				<input
					type="password"
					id="confirm-password"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					required
					minLength={8}
				/>
			</div>

			{errorMessage && <div className={styles.error}>{errorMessage}</div>}

			<button type="submit" disabled={isLoading}>
				{isLoading ? 'Création en cours...' : 'Créer un mot de passe'}
			</button>
		</form>
	);
}

export default SetPasswordForm;

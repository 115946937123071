import {
	Checklist as ChecklistType,
	Response,
	Answer as Answers,
	Question,
	Chapter,
} from '../../sharedTypes';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ReactComponent as PlayFilledIcon } from '../icons/play_circle_filled.svg';
import { ReactComponent as MoreIcon } from '../icons/more.svg';
import styles from './ResponsePage.module.scss';
import ProgressBar from 'components/Checklist/QuestionSelector/ProgressBar';
import Avatar from 'components/Avatar/Avatar';
import CoverImage from 'components/shared/CoverImage/CoverImage';

export default function ResponsePage({
	checklist,
	iframe,
	checklistId,
	response,
	responseIndex,
	firstQuestion,
	currentAnswers,
	setNavigation,
}: {
	checklist: ChecklistType;
	iframe?: boolean;
	checklistId: string;
	response: Response & { folderId: string };
	responseIndex: number;
	firstQuestion: Question;
	currentAnswers: { [key: string]: Answers };
	setNavigation: (navigation: string) => void;
}) {
	return (
		<div className={styles.responsePage}>
			<div className={styles.content}>
				<div className={styles.cover}>
					<CoverImage
						id={checklistId}
						coverImage={checklist?.coverImage}
						width="100%"
						height="10rem"
					/>
					<div className={styles.coverButtons}>
						<div className={styles.flex}>
							{checklist.chapters && checklist.chapters.length > 0 && (
								<button
									onClick={() => {
										setNavigation(
											`question_${
												(checklist.chapters?.[0 as number] as Chapter)
													?.chapterId
											}_${
												(
													checklist.chapters?.[0 as number] as {
														questions: Question[];
													}
												)?.questions?.[0]?.questionId
											}`
										);
									}}
									className={styles.noBorderButton}
								>
									<PlayFilledIcon />
									Voir la réponse complète
								</button>
							)}
							<button className={styles.noBorderButton}>
								<MoreIcon />
							</button>
						</div>
					</div>
				</div>

				<div className={styles.header}>
					<div className={styles.tabBarItems}>
						{response.timestamp &&
							format(new Date(response.timestamp), 'd MMM yyyy, HH:mm', {
								locale: fr,
							})}
					</div>
					{!iframe && (
						<div className={styles.title}>
							{`${(() => {
								const firstAnswer =
									currentAnswers &&
									currentAnswers[responseIndex]?.answer[
										(firstQuestion as { questionId: any })?.questionId
									];
								if (
									firstAnswer &&
									(firstQuestion?.type === 'text' ||
										firstQuestion?.type === 'singleSelect')
								) {
									return firstAnswer;
								}
								return `Réponse #${response.index}`;
							})()}`}
						</div>
					)}
					{currentAnswers &&
						checklist.chapters &&
						checklist.chapters.length > 0 && (
							<ProgressBar
								chapters={checklist.chapters}
								answers={currentAnswers}
								noPadding
								width="10rem"
							/>
						)}
					<div className={styles.modifiedBy}>
						{response.modifiedBy?.slice(0, 2).map((user, userIndex) => (
							<Avatar
								key={`${response.responseId}_${user.userId || userIndex}`}
								name={`${user.userFirstName} ${user.userLastName}`}
								id={user.userId}
							/>
						))}
						{response.modifiedBy && response.modifiedBy.length > 2 && (
							<Avatar
								key={`${response.responseId}_more`}
								name={`+ ${response.modifiedBy.length - 2}`}
								color="grey"
							/>
						)}
						{response.modifiedBy &&
							response.modifiedBy.length > 2 &&
							response?.modifiedBy
								?.slice(-2)
								.map((user) => `${user.userFirstName} ${user.userLastName}`)
								.join(', ')}
					</div>
				</div>

				{checklist.chapters && checklist.chapters.length > 0 && (
					<div className={styles.chapters}>
						<div className={styles.chaptersTitle}>Chapitres</div>
						{checklist.chapters
							?.sort(
								(a: { index: number }, b: { index: number }) =>
									a.index - b.index
							)
							.map((chapter: any, index: number) => (
								<div
									key={chapter.chapterId}
									className={styles.chapterFlex}
									onClick={() => {
										if (chapter.questions.length) {
											setNavigation(
												`question_${chapter.chapterId}_${chapter.questions[0].questionId}`
											);
										}
									}}
								>
									<div className={styles.chapterColumn}>
										<div className={styles.chapterNumber}>
											Chapitre {index + 1}
										</div>
										<div className={styles.chapterTitle}>{chapter.title}</div>
										<div className={styles.chapterContext}>
											{chapter.context}
										</div>
										{currentAnswers && (
											<ProgressBar
												chapter={chapter}
												answers={currentAnswers}
												noPadding
												width="10rem"
											/>
										)}
									</div>
									<PlayFilledIcon />
								</div>
							))}
						<div className={styles.space} />
					</div>
				)}
			</div>
		</div>
	);
}

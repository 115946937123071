import React, { useState } from 'react';
import { useUpdateUserNameMutation } from '../../store/userApi';
import styles from './LoginForm.module.scss';

function SetNameForm() {
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [updateName, { isLoading }] = useUpdateUserNameMutation();
	const [errorMessage, setErrorMessage] = useState<string>('');
	const handleUpdateName = async (e: React.FormEvent) => {
		e.preventDefault();
		setErrorMessage('');
		if (!firstName.trim() || !lastName.trim()) {
			setErrorMessage('Le prénom et le nom sont requis');
			return;
		}
		try {
			await updateName({ firstName, lastName }).unwrap();
			window.history.pushState(null, '', '/');
			window.dispatchEvent(new Event('popstate'));
		} catch (err: any) {
			setErrorMessage(err.data?.error || 'Une erreur est survenue');
			console.error('Set name error:', err);
		}
	};
	return (
		<form onSubmit={handleUpdateName} className={styles.register}>
			<p>Vous pouvez modifier votre nom d'utilisateur ici.</p>
			<div>
				<label htmlFor="first-name">Prénom:</label>
				<input
					type="text"
					id="first-name"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					required
				/>
			</div>
			<div>
				<label htmlFor="last-name">Nom:</label>
				<input
					type="text"
					id="last-name"
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
					required
				/>
			</div>
			{errorMessage && <div className={styles.error}>{errorMessage}</div>}
			<button type="submit" disabled={isLoading}>
				{isLoading ? 'Modification en cours...' : 'Modifier mon nom'}
			</button>
		</form>
	);
}

export default SetNameForm;

import React, {
	useEffect,
	useCallback,
	useRef,
	useMemo,
	useState,
} from 'react';
import styles from './PdfReport.module.scss';
import commonStyles from './common.module.scss';
import {
	Answer as Answers,
	Chapter as ChapterType,
	Checklist as ChecklistType,
	Question as QuestionType,
	Rule,
} from 'sharedTypes';
import { ReactComponent as ArrowIcon } from '../icons/textArrow.svg';
import { ReactComponent as ErrorIcon } from '../icons/error.svg';
import { ReactComponent as SignpostIcon } from '../icons/signpost.svg';
import { ReactComponent as SingleIcon } from '../icons/single.svg';
import { ReactComponent as SoopLogo } from '../icons/soopclLogo.svg';
import { ReactComponent as DownloadIcon } from '../icons/download.svg';
import ProgressBar from 'components/Checklist/QuestionSelector/ProgressBar';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Avatar, { getColorForId } from 'components/Avatar/Avatar';
import Switch from 'components/Switch/Switch';

function getType(type: string) {
	switch (type) {
		case 'multipleSelect':
			return 'Sélection multiple';
		case 'singleSelect':
			return 'Sélection unique';
		case 'text':
			return 'Texte';
		case 'number':
			return 'Nombre';
		case 'dateSelect':
			return 'Date';
		case 'file':
			return 'Fichier';
		case 'media':
			return 'Media';
		default:
			return '';
	}
}

// const MAX_COVER_HEIGHT = 360;
const MAX_SUMMARY_HEIGHT = 560;

export default function PdfReport({
	response,
	answers,
	hasSpacing,
	checklist,
	currentAnswers,
	setSummaryPages,
	initialSummaryPages,
	missionDetails,
	download,
}: {
	response: {
		state: any;
		timestamp: string;
		checklistId: string;
		responseId: string;
		modifiedBy: { userFirstName: string; userLastName: string }[];
	};
	answers: { [key: string]: Answers };
	checklist: ChecklistType;
	currentAnswers: { [key: string]: Answers };
	missionDetails: string;
	hasSpacing?: boolean;
	setSummaryPages: React.Dispatch<React.SetStateAction<any[][]>>;
	initialSummaryPages: any[][];
	download?: (downloadSwitch: boolean) => void;
}) {
	const [openDownloadPopup, setOpenDownloadPopup] = useState(false);
	const [downloadSwitch, setDownloadSwitch] = useState(false);
	const summaryRef = useRef<HTMLDivElement>(null);
	const chapterRefs = useRef<(HTMLDivElement | null)[]>([]);

	useEffect(() => {
		if (summaryRef.current && chapterRefs.current.every((ref) => ref)) {
			let currentPageHeight = 0;
			let currentPage: any[] = [];
			const pages: any[][] = [];

			response.state.chapters?.forEach(
				(chapter: ChapterType, index: number) => {
					const chapterHeight = Math.max(
						chapterRefs.current[index]?.clientHeight || 0,
						41.5
					);

					if (currentPageHeight + chapterHeight > MAX_SUMMARY_HEIGHT) {
						pages.push(currentPage);
						currentPage = [chapter];
						currentPageHeight = chapterHeight;
					} else {
						currentPage.push(chapter);
						currentPageHeight += chapterHeight;
					}
				}
			);

			if (currentPage.length > 0) {
				pages.push(currentPage);
			}

			setSummaryPages(pages);
		}
	}, [response, setSummaryPages]);

	const responseWithRulesApplied = useMemo(() => {
		const toMaskQuestions: string[] = [];

		response.state?.chapters?.forEach((chapter: ChapterType) => {
			chapter.questions?.forEach((question: QuestionType) => {
				if (answers && answers[question.questionId]) {
					if (question.rules) {
						question.rules.forEach((rule: Rule) => {
							if (rule.condition) {
								if (
									(rule.value as string[]).includes(
										answers[question.questionId] as any
									)
								) {
									if (!rule.action) {
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								} else {
									if (rule.action) {
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								}
							} else {
								if (
									(rule.value as string[]).includes(
										answers[question.questionId] as any
									)
								) {
									if (rule.action) {
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								} else {
									if (!rule.action) {
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								}
							}
						});
					}
				}
			});
		});

		const filteredChapters = response.state?.chapters
			?.map((chapter: ChapterType) => {
				const newQuestions = chapter.questions?.filter(
					(question: QuestionType) =>
						!toMaskQuestions.includes(question.questionId)
				);
				return { ...chapter, questions: newQuestions };
			})
			.filter((chapter: ChapterType) => chapter.questions?.length);

		return { ...response.state, chapters: filteredChapters };
	}, [response.state, answers]);

	const flattenQuestions = responseWithRulesApplied.chapters?.reduce(
		(acc: any, chapter: any) => {
			chapter.questions?.forEach((question: any) => {
				if (!question.questionId || !answers?.[question.questionId]) return;
				const answer = answers[question.questionId];
				if (question.type === 'media' && Array.isArray(answer)) {
					(answer as string[]).forEach((mediaAnswer: string) => {
						acc.push({ ...question, answer: mediaAnswer });
					});
				} else {
					acc.push(question);
				}
			});
			return acc;
		},
		[]
	);

	const pdfCoverColor = getColorForId(response.checklistId);

	const calculatePage = useCallback(
		(index: number) => {
			const chapter = response.state.chapters[index];
			if (!chapter?.questions?.[0]?.questionId) return;
			const question = chapter.questions?.[0];
			const questionIndex = flattenQuestions?.findIndex(
				(q: any) => q.questionId === question?.questionId
			);
			return questionIndex + initialSummaryPages?.length + 2;
		},
		[flattenQuestions, initialSummaryPages, response]
	);

	const pageRefs = useRef<(HTMLDivElement | null)[]>([]);

	const scrollToPage = useCallback(
		(chapterIndex: number) => {
			const pageIndex =
				calculatePage(chapterIndex) - (1 + initialSummaryPages?.length);
			pageRefs.current[pageIndex]?.scrollIntoView({ behavior: 'smooth' });
		},
		[calculatePage, initialSummaryPages]
	);

	const avatarCount = response.modifiedBy?.length || 0;
	const baseLeft = -0.6; // Initial left value for 2 avatars
	const additionalLeft = (avatarCount - 3) * 0.5; // Calculate additional left value
	const leftValue = avatarCount > 2 ? baseLeft - additionalLeft : 0;

	return (
		<div
			className={`${styles.pdfReport} ${hasSpacing ? styles.pageSpacing : ''}`}
		>
			{/* Hidden shadow list for measurements */}
			<div
				style={{
					position: 'absolute',
					visibility: 'hidden',
					overflow: 'hidden',
				}}
				className={styles.pdfPage}
			>
				<div ref={summaryRef} className={`${styles.page} ${styles.summary}`}>
					<div className={styles.chapters}>
						{response.state?.chapters?.map((chapter: any, index: number) => (
							<div
								key={`shadow-chapter-${chapter.chapterId}`}
								ref={(el) => (chapterRefs.current[index] = el)}
								className={styles.chapterFlex}
							>
								<div className={styles.chapterColumn}>
									<div className={styles.chapterNumber}>
										Chapitre {index + 1}
									</div>
									<div className={styles.chapterTitle}>{chapter.title}</div>
									<div className={styles.chapterContext}>{chapter.context}</div>
								</div>
								<div className={styles.chapterPage}>
									p{calculatePage(index)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* Cover page */}
			<div className={styles.pdfPage}>
				<div className={`${styles.page} ${styles.cover}`}>
					{response.state?.coverImage ? (
						<img
							className={styles.checklistCoverColorImage}
							src={response.state.coverImage}
							alt="Checklist cover"
						/>
					) : (
						<div
							className={styles.checklistCoverColorImage}
							style={{ background: pdfCoverColor }}
						/>
					)}
					{download && missionDetails && (
						<>
							<button
								className={commonStyles.primaryButton}
								onClick={() => setOpenDownloadPopup(true)}
							>
								<DownloadIcon />
								Télécharger le rapport (PDF)
							</button>
							{openDownloadPopup && (
								<div className={commonStyles.downloadPopup}>
									<div className={commonStyles.downloadPopupTitle}>
										Télécharger le rapport
									</div>

									<div className={commonStyles.spacedFlex}>
										Afficher les données du Lieu sur la première page du rapport
										<div onClick={() => setDownloadSwitch(!downloadSwitch)}>
											<Switch isOn={downloadSwitch} />
										</div>
									</div>
									<button
										className={commonStyles.downloadButton}
										onClick={() => download(downloadSwitch)}
									>
										<DownloadIcon />
										Télécharger
									</button>
								</div>
							)}
						</>
					)}
					<div className={styles.topInfo}>
						<div className={styles.coverFlex}>
							{missionDetails !== '' && (
								<div className={styles.coverMission}>{`Mission #${
									missionDetails.split('**')[0]
								}`}</div>
							)}
							<div className={styles.coverTitle}>{response.state?.title}</div>
						</div>

						<div
							className={`${styles.users} ${
								response.modifiedBy?.length > 1 ? '' : styles.gap6
							}`}
						>
							<div className={styles.modifiedBy}>
								{response.modifiedBy?.map((person: any, index: number) => (
									<Avatar
										medium
										key={`modified_by_avatar_${index}`}
										name={`${person.userFirstName} ${person.userLastName}`}
									/>
								))}
							</div>
							<div
								className={styles.userInfo}
								style={{ left: `${leftValue}rem` }}
							>
								<div className={styles.userName}>
									Remplie par{' '}
									<span>
										{`${response.modifiedBy
											?.map((responsible: any, index: number) => {
												const fullName = `${responsible.userFirstName} ${responsible.userLastName}`;
												if (index === response.modifiedBy.length - 2) {
													return `${fullName} et`; // Add "et" before the last item
												} else if (index === response.modifiedBy.length - 1) {
													return fullName; // Last item, no comma
												} else {
													return `${fullName},`; // Add a comma to separate
												}
											})
											.join(' ')}`}
									</span>
								</div>
							</div>
						</div>

						{currentAnswers &&
							checklist.chapters &&
							checklist.chapters.length > 0 && (
								<ProgressBar
									isPdf
									chapters={checklist.chapters}
									answers={currentAnswers}
									noPadding
									width="10rem"
								/>
							)}
					</div>

					<div className={styles.divider} />

					<div className={styles.locationDetails}>
						{missionDetails !== '' && (
							<>
								<div className={styles.locationTitle}>Données du lieu</div>
								<div className={styles.detailsContainer}>
									<div className={styles.detailsRow}>
										<div className={styles.locationLabel}>
											<SignpostIcon />
											Adresse
										</div>
										<div
											className={`${styles.locationValue} ${styles.addressColor}`}
										>
											{missionDetails.split('**')[1]}
										</div>
									</div>

									<div className={styles.detailsRow}>
										<div className={styles.locationLabel}>
											<SingleIcon />
											Mainteneur
										</div>
										<div className={styles.locationValue}>
											{missionDetails.split('**')[2]}
										</div>
									</div>
								</div>
							</>
						)}
					</div>

					<div className={styles.footer}>
						<div className={styles.soopMission}>
							<SoopLogo />
							{missionDetails !== '' && (
								<>
									{`Mission #${missionDetails.split('**')[0]} | ${
										missionDetails.split('**')[1]
									}`}
								</>
							)}
						</div>
						<div>
							{`1 / ${
								1 + initialSummaryPages?.length + flattenQuestions?.length
							}`}
						</div>
					</div>
				</div>
			</div>

			{/* Summary pages */}
			{initialSummaryPages?.map((page, pageIndex) => (
				<div className={styles.pdfPage} key={`summary-page-${pageIndex}`}>
					<div className={`${styles.page} ${styles.summary}`}>
						<div className={styles.simpleColumn}>
							<div className={styles.header}>
								<div className={styles.column}>
									{missionDetails !== '' && (
										<>{`Mission #${missionDetails.split('**')[0]}`}</>
									)}
									<div className={`${styles.spacedFlex} ${styles.headerTitle}`}>
										{response.state.title}
									</div>
								</div>
							</div>
						</div>

						<div className={styles.divider} />

						<div className={styles.chapters}>
							<div className={styles.summaryTitle}>Sommaire</div>
							{page?.map((chapter: any, index: number) => (
								<div
									key={`chapter-summary-${chapter.chapterId}`}
									className={styles.chapterFlex}
									onClick={() =>
										scrollToPage(
											index +
												(pageIndex > 0
													? initialSummaryPages
															?.slice(0, pageIndex)
															?.reduce((acc, page) => acc + page.length, 0)
													: 0)
										)
									}
									style={{ cursor: 'pointer' }}
								>
									<div className={styles.chapterColumn}>
										<div className={styles.chapterNumber}>
											{`Chapitre ${
												index +
												(pageIndex > 0
													? initialSummaryPages
															?.slice(0, pageIndex)
															?.reduce((acc, page) => acc + page.length, 0)
													: 0) +
												1
											}`}
										</div>
										<div className={styles.chapterTitle}>{chapter.title}</div>
										<div className={styles.chapterContext}>
											{chapter.context}
										</div>
									</div>
									<div className={styles.chapterPage}>
										p
										{calculatePage(
											index +
												(pageIndex > 0
													? initialSummaryPages
															?.slice(0, pageIndex)
															?.reduce((acc, page) => acc + page.length, 0)
													: 0)
										)}
									</div>
								</div>
							))}
						</div>

						<div className={styles.footer}>
							<div className={styles.soopMission}>
								<SoopLogo />
								{missionDetails !== '' && (
									<>
										{`Mission #${missionDetails.split('**')[0]} | ${
											missionDetails.split('**')[1]
										}`}
									</>
								)}
							</div>
							<div>
								{`${pageIndex + 2} / ${
									1 + initialSummaryPages?.length + flattenQuestions?.length
								}`}
							</div>
						</div>
					</div>
				</div>
			))}

			{flattenQuestions?.map((question: any, index: number) => {
				const chapter = response.state.chapters.find(
					(chapter: any) => chapter.chapterId === question.chapterId
				);
				const chapterIndex = response.state.chapters.findIndex(
					(chapter: any) => chapter.chapterId === question.chapterId
				);
				const chapterTotal = response.state.chapters.length;
				const answer = question.answer || answers[question.questionId];

				return (
					<div
						key={`${question.questionId}-${index}`}
						className={styles.pdfPage}
						ref={(el) => (pageRefs.current[index + 1] = el)}
					>
						<div className={styles.page}>
							<div className={styles.simpleColumn}>
								<div className={`${styles.header} ${styles.noPadding}`}>
									{response.state.coverImage ? (
										<img
											src={response.state.coverImage}
											alt="Checklist cover"
										/>
									) : (
										<div
											className={styles.checklistColorImage}
											style={{ background: pdfCoverColor }}
										/>
									)}
									<div className={styles.column}>
										<div>{response.state.title}</div>
										<div className={styles.spacedFlex}>
											{chapter.title}

											<div className={styles.greyText}>
												Chapitre {chapterIndex + 1} / {chapterTotal}
											</div>
										</div>
									</div>
								</div>

								<div className={styles.divider} />

								{chapter.context && (
									<div className={`${styles.greyText} ${styles.textColumn}`}>
										<span>Contexte du chapitre</span>
										<div>{chapter.context}</div>
									</div>
								)}
							</div>

							<div className={styles.content}>
								<div className={styles.blueContainer}>
									<div className={styles.greyText}>
										{getType(question.type)}
									</div>
									<div className={styles.title}>{question.title}</div>
									{(question.type === 'singleSelect' ||
										question.type === 'multipleSelect') && (
										<div className={styles.greyText}>
											Autres choix possibles :{' '}
											<span>
												{question.singleSelect?.options
													?.filter((option: any) => option !== answer)
													.join(', ')}
											</span>
										</div>
									)}
									{answer ? (
										question.type === 'date' ? (
											<div className={styles.answer}>
												<ArrowIcon />
												{format(new Date(answer.toString()), 'd MMMM yyyy', {
													locale: fr,
												})}
											</div>
										) : question.type === 'media' &&
										  question.media?.mediaType === 'photo' ? (
											<div className={styles.answer}>
												{typeof answer === 'string' ? (
													<img src={answer} alt="Media" />
												) : (
													answer?.map((media: string) => (
														<img key={media} src={media} alt="Media" />
													))
												)}
											</div>
										) : (
											<div className={styles.answer}>
												<ArrowIcon />
												{answer.toString()}
											</div>
										)
									) : (
										<div className={styles.noAnswer}>
											<ErrorIcon />
											Absence de réponse
										</div>
									)}
									<ProgressBar
										currentQuestionId={question.questionId}
										chapter={chapter}
										answers={answers}
									/>
								</div>
								{question.context && (
									<div className={`${styles.greyText} ${styles.textColumn}`}>
										<span>Contexte de la question</span>
										<div>{question.context}</div>

										<div className={styles.contextMedia}>
											{/* {question?.contextMedia?.map(
												(media: any, index: number) => (
													<img
														key={index}
														className={styles.contextImg}
														src={media}
														alt="Media"
													/>
												)
											)} */}
											{question.imageMedia && (
												<img
													className={styles.contextImg}
													src={question.imageMedia}
													alt="Media"
												/>
											)}
										</div>
									</div>
								)}
							</div>

							<div className={styles.footer}>
								<div className={styles.soopMission}>
									<SoopLogo />
									{missionDetails !== '' && (
										<>
											{`Mission #${missionDetails.split('**')[0]} | ${
												missionDetails.split('**')[1]
											}`}
										</>
									)}
								</div>
								<div>
									{`${index + initialSummaryPages?.length + 2} / ${
										1 + initialSummaryPages?.length + flattenQuestions?.length
									}`}
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

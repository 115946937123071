import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useGetAnswerQuery, useGetPdfMutation } from '../../store/api';
import {
	Checklist as ChecklistType,
	Answer as Answers,
} from '../../sharedTypes';
import { ReactComponent as EyeIcon } from '../icons/eye.svg';
import { ReactComponent as DownloadIcon } from '../icons/upload.svg';
import PdfReport from './PdfReport';
import styles from './PdfReport.module.scss';
import { useDeviceType } from 'useDeviceType';

const HiddenPdfReport: React.FC<{
	response: any;
	answers: any;
	checklist: ChecklistType;
	currentAnswers: { [key: string]: Answers };
	missionDetails: string;
	setSummaryPages: React.Dispatch<React.SetStateAction<any[][]>>;
}> = ({
	response,
	answers,
	checklist,
	currentAnswers,
	missionDetails,
	setSummaryPages,
}) => {
	return (
		<div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
			<PdfReport
				response={response}
				answers={answers}
				checklist={checklist}
				currentAnswers={currentAnswers}
				setSummaryPages={setSummaryPages}
				initialSummaryPages={[]}
				missionDetails={missionDetails}
			/>
		</div>
	);
};

export default function PdfGenerator({
	checklist,
	currentAnswers,
	response,
	missionDetails,
}: {
	id: string;
	checklist: ChecklistType;
	currentAnswers: { [key: string]: Answers };
	missionDetails: string;
	response: {
		state: ChecklistType;
		timestamp: string;
		responseId: string;
		checklistId: string;
		modifiedBy: {
			userId: string;
			userLastName: string;
			userFirstName: string;
			timestamp: string;
		}[];
	};
}) {
	const { isTypeOrLarger } = useDeviceType();
	const [visualizePdf, setVisualizePdf] = useState(false);
	// const [loading, setLoading] = useState(false);
	const { data } = useGetAnswerQuery(response.responseId, {
		skip: !response.responseId,
	});
	const [getPdf, { isLoading: loading }] = useGetPdfMutation();
	const [summaryPages, setSummaryPages] = useState<any[][]>([]);
	const hiddenReportRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (data && hiddenReportRef.current) {
			// Force a reflow to ensure correct calculations
			hiddenReportRef.current.style.display = 'block';
			setTimeout(() => {
				hiddenReportRef.current!.style.display = 'none';
			}, 0);
		}
	}, [data]);

	const handleDownload = useCallback(
		async (downloadSwitch = false) => {
			try {
				const pdfRequestBody = downloadSwitch
					? {
							responseId: response.responseId,
							missionDetails: missionDetails,
					  }
					: {
							responseId: response.responseId,
					  };

				const pdfResponse = await getPdf(pdfRequestBody);
				if (!pdfResponse.data) {
					throw new Error('No PDF data received');
				}

				const url = window.URL.createObjectURL(pdfResponse.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'rapport.pdf');
				document.body.appendChild(link);
				link.click();
				link.parentNode?.removeChild(link);
				window.URL.revokeObjectURL(url);
			} catch (error) {
				console.error('Erreur lors du téléchargement du PDF :', error);
			}
		},
		[getPdf, response.responseId, missionDetails]
	);

	const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setVisualizePdf(false);
			setSummaryPages([]);
		}
	};

	return (
		<div>
			{isTypeOrLarger('md') && (
				<div className={styles.flex}>
					<button
						className={styles.greyButton}
						onClick={() => handleDownload(false)}
						disabled={loading}
					>
						<DownloadIcon />
						{loading ? 'Génération...' : 'Télécharger le PDF'}
					</button>
					<button
						className={styles.greyButton}
						onClick={() => setVisualizePdf(true)}
					>
						<EyeIcon />
						Visualiser le PDF
					</button>
				</div>
			)}

			{data && (
				<div ref={hiddenReportRef}>
					<HiddenPdfReport
						response={response}
						answers={data}
						setSummaryPages={setSummaryPages}
						checklist={checklist}
						currentAnswers={currentAnswers}
						missionDetails={missionDetails}
					/>
				</div>
			)}

			{visualizePdf && (
				<div onClick={handleBackgroundClick} className={styles.darkBackground}>
					<div
						className={styles.pdfContainer}
						onClick={(e) => e.stopPropagation()}
					>
						{' '}
						{data && (
							<PdfReport
								response={response}
								answers={data}
								hasSpacing
								checklist={checklist}
								currentAnswers={currentAnswers}
								setSummaryPages={setSummaryPages}
								initialSummaryPages={summaryPages}
								missionDetails={missionDetails}
								download={handleDownload}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
